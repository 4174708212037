// import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';

export const LIST_USER_TYPE = [
    { value: "physician", text: "Physician" },
    { value: "crna", text: "CRNA" },
    { value: "srna", text: "SRNA" },
    { value: "resident", text: "Resident" },
    { value: "administrativeassistant", text: "Administrative Assistant" },
    { value: "guest", text: "Guest" }
]
/**
 * Generar las rutas crud de la administracion y las meta: { permission: 'add_group' } dependiendo del CRUD
 *  router   |    django
 *  /       ->   view
 * add      ->   add  
 * update   ->   change
 * delete   ->   delete
 * 
 * /$user_type/             -> view_content_type
 * /$user_type-add/         -> add_content_type
 * /$user_type-update/      -> change_content_type
 * 
 */
export default function () {

    const routes = []

    LIST_USER_TYPE.forEach(el => {
        routes.push({
            name: el.value,
            path: `/${el.value}/`,
            props: { user_type: el.value, user_description: el.text },
            component: () => import('@/components/AdminMaintenance/UserType/PageUserType.vue'),
            meta: { permission: `view_${el.value}` } // campo para validar el permiso de los usuarios
        })
        const camel = capitalize(el.value.toUpperCase())
        routes.push({
            name: el.value + '-add',
            path: `/${el.value}-add/`,
            props: { user_type: el.value, user_description: el.text, edit_mode: false },
            component: () => import(`@/components/AdminMaintenance/UserType/PageManage${camel}.vue`),
            meta: { permission: `add_${el.value}` } // campo para validar el permiso de los usuarios
        })
        routes.push({
            name: el.value + '-edit',
            path: `/${el.value}-edit/:id`,
            props: { user_type: el.value, user_description: el.text, edit_mode: true },
            component: () => import(`@/components/AdminMaintenance/UserType/PageManage${camel}.vue`),
            meta: { permission: `change_${el.value}` } // campo para validar el permiso de los usuarios
        })
    });

    routes.push(
        {
            name: 'roles_help',
            path: `/roles-help`,
            component: () => import('@/components/AdminMaintenance/Roles/Help.vue'),            
        },
    )

    return routes

}