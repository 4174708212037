<template>
  <v-app>
    <v-app-bar app>
      <logo-mbaa class="" />
      <!-- </div> -->
      <v-spacer></v-spacer>
      <!-- <base-lang-select /> -->
    </v-app-bar>
    <v-main v-if="$vuetify.breakpoint.xs">
      <img
        max-height="270"
        src="@/assets/background.png"
        alt="background image xs"
        style="height: auto; max-width: 100%; width: 100%"
        contain
      />
      <reset-pass-card
        v-if="reset_pass"
        @cancel="reset_pass = false"
      ></reset-pass-card>
      <login-card v-else @do-resetpass="reset_pass = true"></login-card>
    </v-main>
    <v-main v-else>
      <v-container fluid style="height: 100%" class="d-flex flex-column pa-0">
        <v-img
          alt="background image"
          eager
          id="image_background"
          
          :src="generate_src()"
        >
          <div style="height: 100%" class="d-flex justify-center align-center">
            <reset-pass-card
              v-if="reset_pass"
              @cancel="reset_pass = false"
            ></reset-pass-card>
            <login-card v-else @do-resetpass="reset_pass = true"></login-card>
          </div>
        </v-img>
      </v-container>
    </v-main>
    <v-footer
      app
      class="pl-4 text-caption blue darken-4 white--text font-weight-light"
    >
      © Copyright MBAA. All Rights Reserved
    </v-footer>
  </v-app>
</template>

<script>
import LogoMbaa from "../components/Home/LogoMbaa";
import LoginCard from "../components/Home/LoginCard";
import ResetPassCard from "../components/Home/ResetPassCard";

export default {
  name: "Login",
  data() {
    return { reset_pass: false };
  },
  components: { LogoMbaa, LoginCard, ResetPassCard },
  created() {},
  methods: {
    generate_src() {
      if (this.$vuetify.breakpoint.lgAndUp)
        return require("../assets/background.png");
      if (this.$vuetify.breakpoint.mdAndDown)
        return require("../assets/background960w.png");
    },
    getImgUrl(value) {
      return require("../assets/" + value + ".png");
    }
  }
};
</script>
<style scoped>
.image_background {
  background-image: url({{@/assets/background.png}});
  background-position: center center;
}
.img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.float-account {
  position: absolute;
  top: 4.5rem;
  border: 2rem !important;
}
</style>
