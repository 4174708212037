/*********************** Physician ****************************/
// Object for Sort Physician
const objPhysician = { 'N': 0, 'P2C': 100, 'P1C': 101, 'V': 102 }
// Generate Object for Sort Physician
const genPhysicianNo = (val) => parseInt(val) || objPhysician[val]

/**
 * 
 * @param {*} arrayItems Array of Physicians
 * @returns sorted arrayItems 
 */
export function sortPhysician(arrayItems) {
    return arrayItems.sort((a, b) => genPhysicianNo(a.no) - genPhysicianNo(b.no))
}


/*********************** Residents ****************************/


/**
 * 
 * @param {*} val value of a.no or a.location
 * @returns 
 */
const genResidentNo = (item) => {
    if (parseInt(item.no) && item.location != "OFF") return item.no
    if (item.no == "V") return 1001
    if (item.location == "OFF") return 1002
    return 1000
}

/**
 * 
 * @param {*} arrayItems Array of Residents
 * @returns sorted arrayItems by no, last_name, OFF to end list
 */
export function sortResident(arrayItems) {
    return arrayItems.sort((a, b) => {
        const sortByNo = genResidentNo(a) - genResidentNo(b)
        const sortByName = a.last_name < b.last_name ? -1 : 1

        return sortByNo === 0 ? sortByName : sortByNo
    })
}

/*********************** OB ****************************/

// Generate Object for Sort Residents
const genOBAndNo = (val) => parseInt(val) || (val == "OB" ? -2 : 1000)


/**
 * 
 * @param {*} arrayItems Array of No+OBs
 * @returns sorted arrayItems by OB, No, last_name
 */
export function sortOBAndNo(arrayItems) {
    return arrayItems.sort((a, b) => {
        const sortByNo = genOBAndNo(a.no) - genOBAndNo(b.no)
        const sortByName = a.last_name < b.last_name ? -1 : 1

        return sortByNo === 0 ? sortByName : sortByNo
    })
}