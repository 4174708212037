import SectionsConfig from "./OptionsConfig.js";
import { mapState } from "vuex";

export default {
    data() {
        return {
            SectionsConfig
        };
    },
    created() {
        // mostrar sections por las dependiendo Rules|Ability -> Role|permissions
        this.loadingSections();
    },

    computed: { ...mapState("auth", ["userLogin"]) },
    methods: {
        /**
         * Retorna la estructura del arreglo de las Opciones para construir el (menu, option y menuDrawer)
         */
        loadingSections() {
            let filterSections = this.SectionsConfig.map(el => ({
                title: el.title,
                listOptions: this.userLogin.is_superuser
                    // para el Super
                    ? el.listOptions.filter(e => !Object.prototype.hasOwnProperty.call(e, 'noInSuper'))
                    // para usuarios que tengan la RULE o NULL (todos acceden)
                    : el.listOptions
                        .filter(e => {

                            if (Object.prototype.hasOwnProperty.call(e, 'onlyInSuper')) {
                                return false
                            }
                            if (Object.prototype.hasOwnProperty.call(e, 'codename')) {
                                return this.$can(e.codename, e.model)
                            }
                            else {
                                return this.$can("read", e.model) || e.model == null
                            }
                        })
                        .filter(e => {
                            if (!Object.prototype.hasOwnProperty.call(e, 'canUsertype')) return true

                            // si esta configurado el Staff que puede acceder
                            return e.canUsertype.some(s => s == this.userLogin.user_type)
                        })
            }));

            // canUsertype - utilizar para permitir acceso dependiendo el Tipo de Staff [physician, crna, resident,tec...]
            filterSections.filter

            // opciones que no se cargan al Super admin
            return filterSections.filter(e => e.listOptions.length);
        },

        get_src(url) {
            return require(`@/assets/${url}`);
        }
    }
}