<template>
  <v-container fluid>
    <v-img height="95vh" src="@/assets/background404.jpg">
      <div
        style="height: 100%"
        class="mr-sm-12 d-flex justify-end align-center"
      >
        <div style="width: 350px">
          <h2 class="text-h3 mb-6 text-center primary--text">
            {{ $t("error_404.error") }}
          </h2>
          <div class="subtitle-1 text-center">{{ $t("error_404.sorry") }}</div>
          <div class="d-flex mt-12 justify-space-around">
            <v-btn width="140" color="primary" outlined @click="$router.go(-1)"
              ><v-icon left>mdi-reply</v-icon> {{ $t("error_404.back") }}</v-btn
            >
            <v-btn width="140" color="primary" :to="{ name: 'dashboard' }"
              ><v-icon left>mdi-home</v-icon
              >{{ $t("error_404.go_home") }}</v-btn
            >
          </div>
        </div>
      </div>
    </v-img>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    CURRENT_LANG: "currentLanguage"
  }),
  created() {
    this.$i18n.locale = localStorage.getItem(this.CURRENT_LANG) || "en";
  }
};
</script>