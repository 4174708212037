import axios from 'axios'
import { make } from '@/utils/core.js';
import i18n from '@/plugins/i18n'
import vm from '@/main';
import { sortPhysician, sortResident, sortOBAndNo } from '../../utils/sorts';

const state = {
    isViewAreas: false,
    list_areas: [],
    schedule_areas: [],
    list_physicians_area: [],

    list_physicians: [],
    list_CRNAs: [],
    list_SRNAs: [],
    list_SRNAsJunior: [],
    list_SRNAsSenior: [],
    list_residents: [],

    schedule_staff: [], // todos los schdeules (MD, SRNA, CRNA, RESIDENTS)
    list_attendings: [],
    list_alerts: [],
    show_add_alert: false
}

const mutations = {
    ...make.mutations(state),

    updScheduleAreas(state, data) {
        let area = state.schedule_areas.find(e => e.id_area == data.id_area)

        area.id_phys = data.id_phys
        area.is_show = data.is_show
        area.closed = data.closed
        area.last_name = data.last_name
    },
}

// ************** Actions ******************
const actions = {

    // ---------- CRUD Areas ------------

    async loadAreas({ commit }) {
        const res = await axios.get('/schedule/area/')
        commit('setListAreas', res.data)
    },
    async addArea({ commit }, area) {
        try {
            const data = await axios.post("schedule/area/", area)
            commit('addListAreas', data.data)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async updateArea({ commit }, area) {
        try {
            // retorna 1 o 0
            const res = await axios.put(`/schedule/area/${area.id}/`, { ...area.data })
            if (res.data) {
                commit('updateListAreas', { id: area.id, data: res.data })
                commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            }
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async deleteArea({ dispatch, commit }, id) {
        try {
            await axios.delete(`/schedule/area/${id}/`)
            commit('deleteListAreas', { id })
            commit('showNotify', { msg: i18n.t('delete_success') }, { root: true })
            dispatch('loadAreas')
        } catch (error) {
            commit('showNotify', { msg: i18n.t('use_area'), color: 'error' }, { root: true })
        }
    },

    // ---------- CRUD Alerts ------------

    async loadSchAlerts({ commit }) {
        const res = await axios.get('/schedule/alert/')
        commit('setListAlerts', res.data)
    },
    async loadSchAlertsByDate({ commit }, date) {
        const res = await axios.get(`/schedule/alert-date/${date}/`)
        commit('setListAlerts', res.data)
    },
    async addSchAlert({ commit }, alert) {
        try {
            const data = await axios.post("schedule/alert/", alert)
            commit('addListAlerts', data.data)
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async updateSchAlert({ commit }, alert) {
        try {
            // retorna 1 o 0
            const res = await axios.put(`/schedule/alert/${alert.id}/`, { ...alert })
            if (res.data)
                commit('updateListAlerts', { id: alert.id, data: res.data })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async deleteAlert({ commit }, id) {
        try {
            await axios.delete(`/schedule/alert/${id}/`)
            commit('deleteListAlerts', { id })
        } catch (error) {
            commit('showNotify', { msg: 'Dont delete', color: 'error' }, { root: true })
        }
    },

    // ---------- CRUD schedule_areas ------------

    async loadScheduleAreas({ commit }, date) {
        const res = await axios.get(`/schedule/schedule_area/${date}/`)
        commit('setScheduleAreas', res.data)
    },

    async loadListPhysiciansArea({ commit }) {
        const res = await axios.get(`/physician/`)
        const upper = res.data
            .filter(e => e.is_active)
            .map(e => ({ ...e, last_name: e.last_name.toUpperCase() }))
        commit('setListPhysiciansArea', listOrderByLastName(upper))
    },

    async assignmentToArea({ commit }, params) {
        try {
            await axios.post(`schedule/schedule_area/${params.date}/`, params.data)
            commit('updScheduleAreas', params.data)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },

    // ----------------- Tables Daily Work Schedule ------------------
    async loadAllSchedules({ commit }, date) {
        const res = await axios.get(`/schedule/schedules-staff/${date}/`)
        const upper = res.data.schedules.map(e => ({ ...e, last_name: e.last_name.toUpperCase() }))
        const errors = res.data.errors

        commit('setScheduleStaff', setIsDuplicate(upper))
        // si tiene permiso de ver los errores
        if (vm.$ability.can("show_errors_notify_daily_work_schedule", "schedule"))
            commit('setErrorsScheduleStaff', errors, { root: true })
    },
    async loadListAttendings({ commit }) {
        const res = await axios.get(`/physician/`)
        const upper = res.data.map(e => ({ ...e, last_name: e.last_name.toUpperCase() }))
        commit('setListPhysicians', upper)
    },

    async loadStaffUser({ commit }, type_user) {
        let data_response = null
        let filtro = []
        switch (type_user) {
            case "Attendings":
                data_response = await axios.get(`/physician/`)
                commit('setListPhysicians', listOrderByLastName(data_response.data))
                break;

            case "Residents":
                data_response = await axios.get(`/resident/`)
                commit('setListResidents', listOrderByLastName(data_response.data))
                break;

            case "CRNAs":
                data_response = await axios.get(`/crna/`)
                commit('setListCRNAs', listOrderByLastName(data_response.data))
                break;

            case "SRNAs":
                data_response = await axios.get(`/srna/`)
                commit('setListSRNAs', listOrderByLastName(data_response.data))
                break;

            case "SRNAs - Senior":
                data_response = await axios.get(`/srna/`)
                filtro = data_response.data.filter(e => e.level == 'Senior')
                commit('setListSRNAsSenior', listOrderByLastName(filtro))
                break;

            case "SRNAs - Junior":
                data_response = await axios.get(`/srna/`)
                filtro = data_response.data.filter(e => e.level == 'Junior')
                commit('setListSRNAsJunior', listOrderByLastName(filtro))
                break;
        }
    },

    async addStaffUser({ dispatch, commit }, params) {
        try {
            const res = await axios.post(`schedule/schedules-staff/${params.date}/`, params.data)
            dispatch('loadAllSchedules', params.date)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return res.data
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async updateStaffUser({ dispatch, commit }, params) {
        try {
            const res = await axios.put(`schedule/schedules-staff/${params.date}/${params.id}/`, params.data)
            dispatch('loadAllSchedules', params.date)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return res.data
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async deleteStaffUser({ commit }, params) {
        try {
            await axios.delete(`schedule/schedules-staff-delete/${params.date}/${params.id}/${params.sch_type}/`)
            commit('deleteScheduleStaff', { id: params.id })
            commit('showNotify', { msg: i18n.t('delete_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify', { msg: i18n.t('use_area'), color: 'error' }, { root: true })
            return false
        }
    },

    async updateAttendingLocation({ commit }, params) {
        try {
            const { id, data } = params
            await axios.put(`schedule/update-attending-locaction/${id}/`, data)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },

    async updatePnoCLocation({ commit }, params) {
        try {
            await axios.put(`schedule/update-pnoc-locaction/`, params)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    }

}

// ************** Gerrters ******************

const getters = {
    schedule_area_active(state) {
        const list = state.schedule_areas.sort((a, b) => {
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;
            return 0;
        });
        if (state.isViewAreas) return list
        return list.filter(e => e.is_show == true)

    },

    // filtro para los ATTENDIGS || QGenda
    schedule_staff_phys(state) {
        /*   let list =  */
        return sortPhysician(state.schedule_staff
            .filter(e => e.sch_type == "Attending" || e.sch_type == "Attendings" || e.sch_type == "QGenda"))

    },
    isHeart(state, getters) {
        return getters.schedule_staff_phys.some(e => e.hrt == true)
    },

    // filtro para los CRNAs
    schedule_staff_crnas(state) {
        return listOrderByNameTimeOB(state.schedule_staff.filter(e => e.sch_type == "CRNAs"))
    },
    // filtro para los SRNAs - Senior
    schedule_staff_srna_senior(state) {
        return listOrderByNameTimeOB(state.schedule_staff.filter(e => e.sch_type == "SRNAs - Senior"))
    },
    // filtro para los SRNAs - Junior
    schedule_staff_srna_junior(state) {
        return listOrderByNameTimeOB(state.schedule_staff.filter(e => e.sch_type == "SRNAs - Junior"))
    },
    // filtro para los Residents
    schedule_staff_resident(state) {
        return sortResident(state.schedule_staff.filter(e => e.sch_type == "Residents"));
    },

}

/**
 * Ordenar por mayor prioridad (OB, Numero y Nombre) menor prioridad
 * @param {*} list 
 */
const listOrderByNameTimeOB = function (list) {
    return sortOBAndNo(list)
}

/**
 * 1) Filtra los usuarios activos y los ordena alfabeticamente por el last_name
 * 2) en caso que el usuario de la lista este en el Daily Work Schedule se elimina de la lista
 *      para evitar duplicados
 * @param {Array} list 
 */
const listOrderByLastName = function (list) {
    return list
        .filter(e => e.is_active)
        .map(e => ({ ...e, last_name: e.last_name.toUpperCase() }))
        .sort((a, b) => {
            if (a.last_name > b.last_name) {
                return 1;
            }
            if (a.last_name < b.last_name) {
                return -1;
            }
            return 0;
        })
}

/**
 * 
 * @param {*} list [
    {
        ca: false
        date: "2022-03-15"
        first_name: "Rick"
        hrt: false
        id: 3198
        id_user: 13
        last_name: "HASTY"
        location: "1800 - 0700"
        no: "N"
        qgenda_id: "Ha"
        sch_type: "Attendings"
        special_rotation: null
    }
 * ]
 */
const setIsDuplicate = (list) => {

    /** {id_user, index} */
    let keepUser = [];
    let index = 0;
    for (const user_schedule of list) {

        user_schedule.is_duplicate = false

        const find = keepUser.find(e => e.id_user == user_schedule.id_user)

        if (find) {
            user_schedule.is_duplicate = true
            list[find.index].is_duplicate = true
        } else {
            keepUser.push({
                id_user: user_schedule.id_user, index
            })
        }

        index++
    }
    // console.log(list);
    return list;
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}