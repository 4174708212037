export default function () {
    // codigo dinamico aqui

    //array de retorno
    return [
        {
            name: 'comprehensive',
            path: `/comprehensive/:date_time/`,
            component: () => import('@/components/Comprehensive/Page.vue'),
            meta: { permission: `view_changeschedulestaff` } // campo para validar el permiso de los usuarios
        },
        // holiday
        {
            name: 'holiday',
            path: `/holiday/:year/`,
            component: () => import('@/components/Holiday/Page.vue'),
            meta: { permission: `view_holiday` } // campo para validar el permiso de los usuarios
        },
        {
            name: 'add-holiday',
            path: `/add-holiday/`,
            component: () => import('@/components/Holiday/PageEdit.vue'),
            meta: { permission: `add_holiday` } // campo para validar el permiso de los usuarios
        },
        {
            name: 'edit-holiday',
            path: `/edit-holiday/:id/`,
            component: () => import('@/components/Holiday/PageEdit.vue'),
            meta: { permission: `change_holiday` } // campo para validar el permiso de los usuarios
        },
    ]
}