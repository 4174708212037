import kebabCase from 'lodash/kebabCase'

/** Users types */
export const PHYSICIAN = 'physician'
export const CRNA = 'crna'
export const SRNA = 'srna'
export const SRNA_JUNIOR = 'srna-junior'
export const SRNA_SENIOR = 'srna-senior'
export const RESIDENT = 'resident'

export const VACATION = 'Vacation'
export const MEETING = 'Meeting'

// Vacations

export const VAC_REQUEST_STATUS = [
    { name: "New", color: "#2196F3", icon: 'mdi-new-box' },
    { name: "Approved", color: "success", icon: 'mdi-check-circle-outline' },
    { name: "Pending", color: "#673AB7", icon: 'mdi-update' },
    { name: "Denied", color: "error", icon: 'mdi-cancel' },
    { name: "Conflict", color: "#FF9800", icon: 'mdi-alert-outline' },
]

// Comprehensive

export const FIRST_ONLY = { value: 'first', text: "First only", color: 'red darken-3 black--text', color_text: 'red--text text--darken-3 font-weight-medium' }
export const SECOND_ONLY = { value: 'second', text: "Second only", color: "blue darken-3 black--text", color_text: 'blue--text text--darken-3 font-weight-medium' }
export const FIRST_SECOND = { value: 'first_second', text: "First and Second", color: "green darken-3 black--text", color_text: 'green--text text--darken-3 font-weight-medium' }

export const SCHEDULED = 'Scheduled'

export function getObjStatus(status) {
    return VAC_REQUEST_STATUS.find(e => e.name == status)
}

// Monthly
export const HOURS = "hours"
export const OB = "OB"
export const VAC = "V"
export const EVE = "EVE"


export const specialItems = [
    { value: "cvt", text: "cvt", color: "red lighten-2" },
    { value: "neuro", text: "neu", color: "yellow darken-1" },
    { value: "regional", text: "reg", color: "green darken-1" },
    { value: "pacu", text: "pac", color: "blue darken-1" }
]

// función manual para clonar arrays
export const clone = items => items.map(item => (Array.isArray(item) ? clone(item) : item))

/** 
* Registro de Componenetes **Base** en la instancia de Vue
* con el Formato: `base-componen-name-kebakcase`
* */
export function initConfigs(vm) {
    /** 
     * Registro de Componenetes **Base** en la instancia de Vue
     * con el Formato: vst-componen-name-kebakcase
     * */
    const requireComponent = require.context(
        // path del directorio
        '@/components/Base',
        // buscar en subdirectorios
        true
    )

    requireComponent.keys().forEach(fileName => {
        // configuracion de los componenetes
        const componentConfig = requireComponent(fileName)

        // trabajar solo en las extenciones .vue o .js 
        if (fileName.endsWith('.vue') || fileName.endsWith('.js')) {

            //nombre del componente en KamelCase ej: `./Organisms/Banner.vue` --> obtiene (`Banner`)
            const cpnt = fileName.replace(/(.*)\.\w+$/, '$1').split('/').pop()
            // nombre del componente compuesto por el prefijo `vst-` y componente en KebabCase
            const componentName = `base-${kebabCase(cpnt)}`

            // Registrar el commponente de forma Global en la instancia de Vue
            vm.component(
                componentName,
                componentConfig.default || componentConfig
            )
        }

    })
}


export const make = {

    /**
     * crea las mutaciones dependiendo del tipo de **state** 
     * 
     * `object` -> CRUD (set | add | update | delete)
     * 
     * `number` -> set | increment | decrement
     *
     * `boolen` -> set | invert
     * 
     * `others` -> set
     * @param {{}} state estado de algun store 
     * @returns {{ setStateProperty: Function,
     * addStateProperty: Function, 
     * updateStateProperty: Function, 
     * deleteStateProperty: Function, 
     * incrementStateProperty: Function, 
     * decrementStateProperty: Function, 
     * invertStateProperty: Function, 
     * } Object de mutations dependiendo del tipo
     */
    mutations(state) {

        const keys_vales = Object.entries(state)
        let mutations = {}

        //recorrer state
        keys_vales.forEach(onekey => {
            // onekey[key, value]
            switch (typeof onekey[1]) {
                case 'object':// array and object and null
                    if (Array.isArray(onekey[1]))
                        Object.assign(mutations, this.mutationsCRUD(onekey[0]))
                    else { //para los {objets}
                        const keyCamelCase = this.keyToCamelCase(onekey[0])
                        Object.assign(mutations, {
                            [`set${keyCamelCase}`]: function (state, items) {
                                Object.assign(state[onekey[0]], { ...items })
                            }
                        })
                    }
                    break;
                case 'number':
                    Object.assign(mutations, this.mutationNumber(onekey[0]))
                    break;
                case 'boolean':
                    Object.assign(mutations, this.mutationBoolen(onekey[0]))
                    break;
                default: {// 'string' and others -- only (set)
                    const keyCamelCase = this.keyToCamelCase(onekey[0])
                    Object.assign(mutations, {
                        [`set${keyCamelCase}`]: function (state, items) {
                            state[onekey[0]] = items
                        }
                    })
                    break;
                }
            }
        })
        // LOG      
        // if (process.env.NODE_ENV == 'development')
        // console.log('mutations => ', mutations)

        return mutations

    },

    /**
     * Creación de las 4 mutaciones que representan el **CRUD** de un *state*,
     * del stado que representa el key, con el formato  `(set | add | update | delete)KeyName`
     *Ej. `list__actions` -> setListActions(), addListActions(), updateListActions(), deleteListActions()
     * @param {String} key_state key del state representa un (Objeto | Array) 
     * @returns {{setKeyName(),addKeyName(),updateKeyName(),deleteKeyName()}} mutaciones para la key
     */
    mutationsCRUD(keyValue) {
        // formatear el nombre de la key (__) a kamelCase
        const keyCamelCase = this.keyToCamelCase(keyValue)

        // crear CRUD por cada key
        return {
            [`set${keyCamelCase}`]: function (state, items) {
                state[keyValue] = items
            }
            ,

            [`add${keyCamelCase}`]: function (state, item) {
                state[keyValue].push(item)
            }
            ,

            [`update${keyCamelCase}`]: function (state, item) {
                Object.assign(state[keyValue].find(el => el.id == item.id), item.data);
            }
            ,

            [`delete${keyCamelCase}`]: function (state, item) {
                state[keyValue].splice(state[keyValue].findIndex(el => el.id == item.id), 1);
            }
        }
    },

    /**
     * Creación de las mutaciones (set | increment | decrement)KeyName de un *state*,
     *Ej. `count` -> setCount(), incrementCount(), decrementCount()
     * @param {String} key_state key del state representa un (Number) 
     * @returns {{setKeyName(),incrementKeyName(),decrementKeyName()}} mutaciones para la key
     */
    mutationNumber(keyValue) {
        // formatear el nombre de la key (__) a kamelCase
        const keyCamelCase = this.keyToCamelCase(keyValue)

        // crear y retornar (set | increment | decrement)
        return {
            [`set${keyCamelCase}`]: function (state, value) {
                state[keyValue] = value
            },
            [`increment${keyCamelCase}`]: function (state, cant = 1) {
                state[keyValue] += cant
            },
            [`decrement${keyCamelCase}`]: function (state, cant = 1) {
                state[keyValue] -= cant
            }
        }
    },

    /**
     * Creación de las mutaciones (set | invert )KeyName de un *state*,
     *Ej. `isDown` -> setIsDown(), invertIsDown
     * @param {String} key_state key del state representa un (Boolean) 
     * @returns {{setKeyName(),invertKeyName(),decrementKeyName()}} mutaciones para la key
     */
    mutationBoolen(keyValue) {
        // formatear el nombre de la key (__) a kamelCase
        const keyCamelCase = this.keyToCamelCase(keyValue)

        // crear y retornar (set | invert)
        return {
            [`set${keyCamelCase}`]: function (state, value) {
                state[keyValue] = value
            },
            [`invert${keyCamelCase}`]: function (state) {
                state[keyValue] = !state[keyValue]
            }
        }
    },



    /**
       * formatear el nombre de la keyValue a camelCase, eliminando (_)
       * @param {String} keyValue key del store
       */
    keyToCamelCase(keyValue) {
        // formatear el nombre de la key (__) a camelCase
        // return this.charAt().toLocaleUpperCase() + this.substring(1)//.toLocaleLowerCase()
        return keyValue.split('_').filter(el => !!el).map(el => el.charAt().toLocaleUpperCase() + el.substring(1)).join('')
    }


}