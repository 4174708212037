import axios from 'axios'
import { make } from '@/utils/core.js';
import i18n from '@/plugins/i18n'
import { getObjStatus } from '../../utils/core';
import { DateTime } from "luxon";

const state = {
    chatItem: {
        // id_request : #
        // name       : leandro
        // type       : vacation | meeting
        comments: []
    },
    list_vacations_request: [],
    count_new_request: 0,

    // logs
    loading_time_line: false,
    year_period_start: (() => {
        const current_date = new Date();
        if (current_date.getMonth() < 7) return current_date.getFullYear() - 1;
        else return current_date.getFullYear();
    })(),
    vacation_logs: [],
    carry_over: 0,
    starting_balance: 30,

    // crna
    pay_period_list: [],
    hours_remaining: 0,
    adicional_hours: []

}

const mutations = {
    ...make.mutations(state),

    /**
     * Add new comment to de @params id_request 
     * @param {*} params 
     */
    addCommentToVRequest(state, params) {
        const { id_request, comment } = params

        let request = state.list_vacations_request.find(e => e.id == id_request)
        request.comments.push(comment)
    },

    updateStatusRequest(state, params) {
        const { idVrequest, status } = params
        let request = state.list_vacations_request.find(e => e.id == idVrequest)
        request.status = status
    },

    setTodayYearPeriodStart(state) {
        const current_date = new Date();
        if (current_date.getMonth() < 7) state.year_period_start = current_date.getFullYear() - 1;
        else state.year_period_start = current_date.getFullYear();
    }
}

// ************** Actions ******************
const actions = {

    loadCountNewRequest({ commit }) {
        axios.get('/vacation/count-new-reauest/')
            .then(res => {
                commit('setCountNewRequest', res.data)
            })
    },

    // ---------- CRUD Request ------------
    loadVacationsRequestUser({ commit }, filters) {
        axios.get(`/vacation/vacations-request-filter/${JSON.stringify(filters)}/`)
            .then(res => {
                commit('setListVacationsRequest', res.data)
            })
    },

    /**
     * cargar los VRequest para el admin, por defecto los New y Pending
     * @param {*} filters 
     * {
     *    status: [], staff: [], isVacation, isMeeting
     * }
     */
    loadVacationsRequestAdmin({ commit }, filters) {
        axios.get(`/vacation/vacations-request-admin/${JSON.stringify(filters)}/`)
            .then(res => {
                commit('setListVacationsRequest', res.data)
            })
    },
    async newVRequest({ commit }, vrequest) {
        try {
            const data = await axios.post("vacation/vacations-request/", vrequest)
            commit('addListVacationsRequest', data.data)

            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async newVRequestUser({ commit }, vrequest) {
        try {
            const data = await axios.post(`vacation/vacations-request-user/${vrequest.user}/`, vrequest)
            commit('addListVacationsRequest', data.data)

            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error', time: 6000 },
                { root: true })
            return false
        }
    },

    async editVRequest({ commit }, vrequest) {
        try {
            const res = await axios.put(`/vacation/vacations-request/${vrequest.id}/`, vrequest)
            if (res.data) {
                commit('updateListVacationsRequest', { id: vrequest.id, data: res.data })
                // dispatch('loadVacationsRequestUser')
                commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            }
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async changeStatusVRequest({ commit }, params) {
        try {
            const { idVrequest, status } = params
            await axios.put(`/vacation/change-status-vrequest/${idVrequest}/`, { status })
            // commit('updateStatusRequest', params)
            // dispatch('loadVacationsRequestUser')
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error', time: 6000 },
                { root: true })
            return false
        }
    },

    // ---------- Comments ------------
    async newComment({ commit }, params) {
        try {
            const { id_request, comment } = params
            const data = await axios.post(`vacation/comments/${id_request}/`, comment)
            commit('addCommentToVRequest', { id_request, comment: data.data })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },



    // ---------- LOGS ------------

    async loadLogsByUserAndPerido({ commit }, filters) {
        try {
            const { id_user, period_start } = filters
            commit('setLoadingTimeLine', true)
            const res = await axios.get(`/vacation/load-logs-for-user/${id_user}/${period_start}/`)
            setTimeout(() => {
                commit('setLoadingTimeLine', false)

            }, 50)
            const { logs, starting_balance, carry_over } = res.data;
            commit('setVacationLogs', logs)
            commit('setStartingBalance', starting_balance)
            commit('setCarryOver', carry_over)
            return true
        } catch (err) {
            console.log(err);
            return false
        }

    },

    async loadLogsCrnaByPerido({ commit }, filters) {
        try {
            const { id_user, period_start } = filters
            commit('setLoadingTimeLine', true)
            const res = await axios.get(`/vacation/load-logs-for-crna/${id_user}/${period_start}/`)
            setTimeout(() => {
                commit('setLoadingTimeLine', false)
            }, 50)
            const { pay_period_list, hours_remaining, adicional_hours, starting_balance, carry_over } = res.data;
            commit('setPayPeriodList', pay_period_list)
            commit('setHoursRemaining', hours_remaining)
            commit('setAdicionalHours', adicional_hours)
            commit('setStartingBalance', starting_balance)
            commit('setCarryOver', carry_over)
            return true
        } catch (err) {
            console.log(err);
            return false
        }

    },

    async editBalance({ dispatch, commit }, params) {
        try {
            await axios.post(`vacation/change-balance-period/`, params)
            // commit('setStartingBalance', params.starting_balance)
            dispatch('loadLogsByUserAndPerido', params)
            dispatch('loadLogsCrnaByPerido', params)
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async editDaysUsed({ commit }, params) {
        try {
            const { id_request, data } = params
            await axios.post(`vacation/change-days-used/${id_request}/`, data)
            // dispatch('loadLogsByUserAndPerido', params)
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },

    async editPPUsed({ commit }, params) {
        try {
            await axios.post(`vacation/change-pp-used/`, params)
            // dispatch('loadLogsByUserAndPerido', params)
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },

    async addAdicionalHours({ dispatch, commit }, params) {
        try {
            const resp = await axios.post(`vacation/aditional-hours/`, params)
            commit('addAdicionalHours', resp.data)
            dispatch('loadLogsCrnaByPerido', params)
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },

    async onUpdateAdicionalHours({ dispatch, commit }, params) {
        try {
            const { id, adicional } = params
            await axios.put(`vacation/aditional-hours/${id}/`, adicional)
            // commit('updateAdicionalHours', { id: id, data: resp.data })
            dispatch('loadLogsCrnaByPerido', params)
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async deleteAditionalHours({ dispatch, commit }, params) {
        try {
            await axios.delete(`/vacation/aditional-hours/${params.id}/`)
            dispatch('loadLogsCrnaByPerido', params)
            commit('showNotify', { msg: i18n.t('delete_success') }, { root: true })
            dispatch('loadAreas')
        } catch (error) {
            commit('showNotify', { msg: i18n.t('use_area'), color: 'error' }, { root: true })
        }
    },

}

// ************** Gerrters ******************

const getters = {
    /**
     * Las nuevas siempre al Inicio     
     */
    listOrderVacationsRequest(state) {
        return state.list_vacations_request
            .sort((a, b) => {
                if (DateTime.fromISO(a.period_start) < DateTime.fromISO(b.period_start)) return 1
                else return -1

            })
            .sort((a, b) => {
                if (a.status == 'Approved' && b.status != 'Approved') return 1
                else return -1

            })

            .sort((a, b) => {

                // Las nuevas siempre al Inicio     
                if (a.status == 'New' && b.status != 'New') return -1
                else return 1
            });

    },
    vacations_request_calendar(state) {
        return state.list_vacations_request.map(e => ({
            icon: `$${e.type.toLowerCase()}`,
            name: e.last_name,
            start: new Date(`${e.period_start}T00:00:00`),
            end: new Date(`${e.period_end}T10:59:59`),

            color: getObjStatus(e.status).color,
            timed: true,
            vrequest: e
        }))
        // .sort((one, two) => one.siglas.toLowerCase() > two.siglas.toLowerCase() ? 1 : -1)
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}