<template>
  <v-card style="background-color: transparent !important" width="390">
    <v-card-text class="opacity-bg">
      <v-form ref="form">
        <v-text-field
          prepend-inner-icon="mdi-email-outline"
          class="mt-6"
          :label="$t('email')"
          :rules="rules_user"
          outlined
          name="username"
          v-model="username"
        >
          <template v-slot:message="{ message }">
            {{ $i18n.t(message) }}
          </template>
        </v-text-field>
        <v-text-field
          prepend-inner-icon="mdi-lock-open-outline"
          :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show_pass ? 'text' : 'password'"
          :rules="rules_password"
          :label="$t('password')"
          outlined
          name="password"
          v-model="password"
          @click:append="show_pass = !show_pass"
          @keypress.enter="onLogin"
        >
          <template v-slot:message="{ message }">
            {{ $i18n.t(message) }}
          </template>
        </v-text-field>
        <!-- v-button -->
        <v-btn :loading="loading" @click="onLogin" block color="primary" dark>{{
          $t("login")
        }}</v-btn>
        <p class="caption mt-3 mb-0">
          {{ $t("login__forgot_password.forgot") }}
          <v-btn text color="primary" class="px-1" x-small @click="doReset">{{
            $t("login__forgot_password.retrieve")
          }}</v-btn
          >{{ $t("login__forgot_password.email") }}
        </p>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import { loginMixin } from "@/mixins/rules_mixin";

export default {
  mixins: [loginMixin],
  data: () => ({
    show_pass: false,
    loading: false,
    username: "",
    password: ""
  }),

  methods: {
    ...mapActions("auth", ["login"]),
    async onLogin() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const islogin = await this.login({
          username: this.username,
          password: this.password
        });
        this.loading = false;
        if (islogin) this.$router.push({ name: "dashboard" });
      }
    },

    doReset() {
      this.$emit("do-resetpass");
    }
  }
};
</script>
<style scoped>
.opacity-bg {
  background-color: rgba(232, 232, 232, 0.8);
}
</style>