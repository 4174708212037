import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
// import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import { initConfigs } from '@/utils/core';
import 'vue-croppa/dist/vue-croppa.css'


// *************** Axios **********************\\
import axios from 'axios'
import vueaxios from 'vue-axios'
import i18n from './plugins/i18n'
Vue.use(vueaxios, axios)

axios.defaults.baseURL = process.env.VUE_APP_API

// *************** CASL **********************\\
import { abilitiesPlugin } from '@casl/vue';
import ability from './configs/ability';
Vue.use(abilitiesPlugin, ability);

// *************** CKEditor **********************\\
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);

// *************** VueHtmlToPaper **********************\\
import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css',
    'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css'
    /* 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css' */
  ],
  // timeout: 1000, // default timeout before the print window appears
  // autoClose: false, // if false, the window will not close after printing
  // windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
// Vue.use(VueHtmlToPaper);

// *************** VueHtmlToPaper **********************\\

import Croppa from 'vue-croppa'

Vue.use(Croppa)


//**********************************************/
Vue.config.productionTip = false

initConfigs(Vue)

export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
