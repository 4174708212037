import vm from '@/main';
import { Ability } from '@casl/ability';

export default new Ability([])

/**
 * Array de permisos que tiene acceso el usuario @param userLogin 
 * 
 * algunos ejemplos para utilizar los permisos en Vuejs
 * @example v-if="$ability.can('print_daily_work_schedule', 'schedule')"
 * @example v-if="$ability.can('read', 'area')"  => view_area
 * @example v-if="$ability.can('edit', 'area')"  => change_area
 * @example this.$can('read', 'area')
 * @param {*} userLogin 
 */
export const createAbilitys = function (userLogin) {

    let abilitys = []
    // Full access for superadmin
    if (userLogin.is_superuser) abilitys.push({ subject: 'all', action: 'manage' })

    // rules para los usuarios dependiendo del Role|Group
    // generar el (CRUD|casl)  mediante los permisos de Django
    else {
        if (userLogin.groups.length) {
            const allpermissions = userLogin.groups[0].permissions

            for (const rule of allpermissions) {

                let action = ''
                // view   ->   read
                if (rule.codename.startsWith('view_'))
                    action = 'read'

                // add   ->   create
                else if (rule.codename.startsWith('add_'))
                    action = 'create'

                // change   ->   update
                else if (rule.codename.startsWith('change_'))
                    action = 'update'

                // delete   ->   delete
                else if (rule.codename.startsWith('delete_'))
                    action = 'delete'

                else
                    // custom actions
                    action = rule.codename

                abilitys.push({
                    subject: rule.content_type.model,
                    action
                })
            }
        }
    }
    console.log("abilitys >> ",abilitys, " <<");
    vm.$ability.update(abilitys);
}