<template>
  <div class="d-flex align-center">
    <router-link :to="{name: 'dashboard'}" v-if="isClicked">
      <v-img src="@/assets/logo.svg" width="50" contain></v-img>
    </router-link>
    <v-img v-else src="@/assets/logo.svg" height="42" width="50" contain></v-img>

    <div class="ml-5">
      <h3 class="display-5 font-weight-regular primary--text">MBAA</h3>
      <p class="subtitle-2 font-weight-regular mb-0 d-none d-sm-flex">
        Miami Beach Anesthesiology Associates
      </p>
      <!-- d-none d-sm-flex -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isClicked: {
      type: Boolean,
      defaulr: false,
    },
  },
  data: () => ({}),
  created() {},
  methods: {
    goDashboard() {
      if (this.isClicked)
        this.$router.push({ name: "dashboard" }).catch(() => {});
    },
  },
};
</script>
<style scoped>
.clicked {
  cursor: pointer;
}
</style>