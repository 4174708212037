<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-x
    offset-y
    nudge-bottom="5"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="grey darken-2"
        outlined
        fab
        class="mx-2"
        x-small
        v-bind="attrs"
        v-on="on"
        ><v-icon color="grey darken-2" size="24"
          >mdi-account-tie-outline</v-icon
        ></v-btn
      >
    </template>

    <v-card width="344">
      <v-img
        class="align-center"
        height="200px"
        src="@/assets/backgraund-user.jpg"
      >
        <div class="black--text text-center text-h6" style="height: 100%">
          {{ typeTExt }}
        </div>
      </v-img>
      <v-card-text class="text-center">
        <div class="mt-6 text-subtitle-1 font-weight-bold">
          {{ userLogin.first_name }} {{ userLogin.last_name }}
        </div>
        <div>{{ userLogin.email }}</div>

        <v-btn
          small
          outlined
          rounded
          :to="{ name: 'user_profile' }"
          @click="toProfile"
          class="ma-2 text-capitalize"
          color="primary"
        >
          <v-icon left> mdi-account-edit </v-icon>{{$t('profile')}}
        </v-btn>

        <v-btn outlined class="mt-10" color="indigo" block @click="onLogout"
          >{{$t('logout')}}<v-icon right size="18">mdi-logout</v-icon></v-btn
        >
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { LIST_USER_TYPE } from "@/router/modules/admin";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      LIST_USER_TYPE,
      menu: false
    };
  },
  computed: {
    ...mapState("auth", ["userLogin"]),
    typeTExt() {
      if (this.userLogin.is_superuser) return "Super Admin";
      const user = this.LIST_USER_TYPE.find(
        e => e.value == this.userLogin.user_type
      );
      return user.text;
    }
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    onLogout() {
      if (this.logout()) this.$router.push({ name: "login" });
    },
    toProfile() {
      this.menu = false;
    }
  }
};
</script>