<template>
  <v-app style="position: relative;">
    <!-- Notificación -->
    <v-snackbar
      v-model="notify.visible"
      top
      :color="notify.color"
      :timeout="notify.time"
    >
      <v-icon v-if="notify.icon" left>{{ notify.icon }}</v-icon>
      <samp
        class="text-body-2"
        :class="{ 'text-capitalize': notify.capitalize }"
        >{{ notify.msg }}</samp
      >

      <template v-slot:action="{ attrs }">
        <v-btn icon @click="closeNotify" v-bind="attrs">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Contenido -->
    <router-view />
    <v-btn
      class="elevation-21 hide-in-print"
      color="primary"
      v-show="fab"
      v-scroll="onScroll"
      small
      dark
      fab
      bottom
      right
      fixed
      @click="top"
      ><v-icon>mdi-chevron-up</v-icon></v-btn
    >
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "App",
  data: () => ({
    fab: false
  }),
  computed: {
    ...mapState(["notify"])
  },
  methods: {
    ...mapMutations(["closeNotify"]),
    onScroll() {
      return;
      // if (typeof window === "undefined") return;
      // const top = window.pageYOffset || e.target.scrollTop || 0;
      // this.fab = top > window.visualViewport.height;
    },
    top() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>
<style>
.max-w-700 {
  max-width: 700px !important;
}
.max-w-800 {
  max-width: 800px !important;
}
.max-w-1000 {
  max-width: 1000px !important;
}
.max-w-1400 {
  max-width: 1400px !important;
}
.max-h-1000 {
  max-height: 1000px !important;
}
</style>