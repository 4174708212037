export default function () {
    // codigo dinamico aqui

    //array de retorno
    return [
        {
            name: 'vacations_request_tracker_admin',
            path: `/vacations-request-tracker/admin/`,
            component: () => import('@/components/Vacation/RequestTracker/PageAdmin.vue'),
            meta: { permission: `view_vrequestadmin` } // campo para validar el permiso de los usuarios
        },
        {
            name: 'vacations_request_tracker',
            path: `/vacations-request-tracker/`,
            component: () => import('@/components/Vacation/RequestTracker/PageUser.vue'),
            meta: { permission: `view_vrequest` } // campo para validar el permiso de los usuarios
        },
        {
            name: 'user_staff_logs',
            path: `/logs/:user_type/`,
            component: () => import('@/components/Vacation/VLogs/PageStaffLogs.vue'),
            meta: { permission: `view_requestlogs`, } // campo para validar el permiso de los usuarios
        },
        {
            name: 'physician_logs_admin',
            path: `/logs-admin/:user_type/`,
            component: () => import('@/components/Vacation/VLogs/PageAdminLogs.vue'),
            meta: { permission: ['logs_admin_physician', 'logs_admin_resident'] } // campo para validar el permiso de los usuarios
        },
        {
            name: 'crna_logs',
            path: `/logs-crna/`,
            component: () => import('@/components/Vacation/VLogs/PageCrnaLogs.vue'),
            meta: { permission: `view_requestlogs` } // campo para validar el permiso de los usuarios
        },
        {
            name: 'crna_logs_admin',
            path: `/logs-crna-admin/`,
            component: () => import('@/components/Vacation/VLogs/PageAdminCrnaLogs.vue'),
            meta: { permission: `logs_admin_crna` } // campo para validar el permiso de los usuarios
        },
    ]
}