import axios from 'axios';
export const CURREN_USER = 'user_login_token'

export default {
    /**
     * loggin del usuario en la app y guarda los valores del {id, user, full_name, _token}
     * en el localStores(currentUser), el rol se recupera posteriormente por cuestiones de seguridad
     */

    login(user) {
        localStorage.setItem(CURREN_USER, JSON.stringify(user))
        this.registerHeadersTokenAxios()
    },

    /**
     * elimina los datos del localStore(currentUser)
     * 
     * `y posteriormente algunos datos extra del usuario`
     */
    logout() {
        localStorage.removeItem(CURREN_USER)
        this.unRegisterHeadersTokenAxios()
    },

    /**
     * @returns {boolean} si existe algun usuario del tipo que sea loggeado en la app,
     * mediante la validacion del !!localStore(currentUser)
     */
    isLogin() {
        return !!localStorage[CURREN_USER]
    },
    getToken() {
        const user = JSON.parse(localStorage.getItem(CURREN_USER))
        return user
    },

    /**
     * @returns {Object} la configuracion de los Headers necesarias para enviar el _token a la API
     */
    getHeaders() {
        return {
            headers: {
                'access-token': this.getToken()
            }
        }
    },
    registerHeadersTokenAxios() {
        // Authorization: Token 9944b09199c62bcf9418ad846dd0e4bbdfc6ee4b
        axios.defaults.headers.common['Authorization'] = 'Token ' + this.getToken()
    },
    unRegisterHeadersTokenAxios() {
        axios.defaults.headers.common['Authorization'] = ''
    }
}