import axios from 'axios'
import { make } from '@/utils/core.js';
import authSession from '@/utils/auth.js';
import i18n from '@/plugins/i18n'
import { createAbilitys } from '../../configs/ability';
import capitalize from 'lodash/capitalize';
const state = {
    userLogin: null
}

const mutations = {
    ...make.mutations(state),
    setUserLogin(state, data) {
        state.userLogin = data

        // crear las teglas CASL/Ability
        createAbilitys(data)
    }
}

// ************** Actions ******************
const actions = {
    /**
     * Login en la app, mediante email/password, 
     * se guarda el token en local y los datos del usuario en el estado `userLogin`
     * @param {*} userdata {username: email, password: *****}
     */
    async login({ commit }, userdata) {
        try {
            const res = await axios.post('/login/', userdata)
            // datos del usuario en el estado `userLogin` y se generan las teglas CASL/Avility

            commit('setUserLogin', res.data.user)
            // guarda el token en local
            authSession.login(res.data.token)
            return true;
        } catch (error) {
            console.log(error);
            commit('showNotify', { msg: i18n.t('error_login'), color: 'error', icon: 'mdi-shield-alert' }, { root: true })
            return false
        }
    },

    async logout() {
        try {
            await axios.post('/logout/')
            authSession.logout()
            return true;
        } catch (error) {
            return false
        }
    },

    async changePasswod({ commit }, data) {
        try {
            await axios.put('/change-password/', data)
            commit('showNotify', { msg: 'Password Updated Successfully!' }, { root: true })
            return true
        } catch (error) {
            commit('showNotify', {
                msg: 'Error: ' + error.response.data.error,
                color: 'error', icon: 'mdi-shield-alert'
            }, { root: true })
            return false
        }
    },

    /**
     * Carga los datos del Usuario logeado, cuando se actualiza la pagina 
     * se envia el token a DRF y se recargan los datos del usuario     
     */
    async loadDataToken({ commit }) {
        try {
            authSession.registerHeadersTokenAxios()
            const res = await axios.get('/get-userdata/')

            // datos del usuario en el estado `userLogin` generan las teglas CASL/Avility
            commit('setUserLogin', res.data)

        } catch (error) {
            console.log(error + '---------------');
        }
    }
}

// ************** Gerrters ******************

const getters = {

    isFirstLogin(state) {
        return !state.userLogin.last_login
    },

    userLoginFullName(state) {
        return `${capitalize(state.userLogin.first_name)} ${capitalize(state.userLogin.last_name)}`
    }

}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}