export default {

    // ------- Login -------
    lang: "Spanish",
    login__forgot_password: {
        forgot: "Olvidó su contraseña? ",
        retrieve: "Recupere su contraseña",
        email: " con su dirección de correo electrónico.",
    },
    email: "Correo",
    password: "Contraseña",
    password_confirm: "Confirmar contraseña",
    reset_pass_confirm: "Se ha enviado un correo con un enlace para restaurar la contraseña. ",
    reset_pass_error: "Hubo un problema. No podemos encontrar una cuenta con esa dirección de correo electrónico. Por favor contacte a su administrador.",
    login: "entrar",
    error_login: 'correo o contraseña incorrectos.',
    reset_pass: "Reiniciar Contraseña",

    logout: "Salir",
    profile: "Perfil",

    // ------------- Validations -----------
    rules: {
        required: "valor requerido.",
        email: 'correo incorrecto.',
        max_length_10: 'el campo no debe exeder los 10 caracteres',
        cell_phone: 'formato de teléfono : ###-###-####',
        graduation_year: 'formato incorrecto: ####',
        hours: 'campo numérico'
    },

    // ------------- 404 -----------
    error_404: {
        error: "Error 404",
        sorry: 'Página no encontrada',
        back: 'ir atrás',
        go_home: 'inicio'
    },

    // ------------ Forms -------------
    search: "Buscar",
    add: "Adicionar",
    name: 'Nombre',
    first_name: "Nombre",
    last_name: "Apellidos",
    cell_phone: "Teléfono movil",
    home_phone: "Teléfono fijo",
    home_address: "Dirección particular",
    start_date: "Fecha de inicio",
    biography: "biografía",
    admin_privileges: "Privilegio administración",
    is_active: "Activo",
    social_media: "Redes Sociales",
    emergency_name: "Contacto Emergencia, Nombre",
    emergency_relationship: "Contacto Emergencia, Relación",
    emergency_number: "Contacto Emergencia, Número",

    hours: "Horas",
    level: "Nivel",
    graduation_year: "Año de Graduación",
    school: "Escuela",

    cancel: "Cancelar",
    open: "Abrir",
    close: "Cerrar",
    edit: "Editar",
    delete: "Eliminar",
    confirm: "Confirmar",
    save: "Guardar",
    send: 'Enviar',
    find: 'Buscar',
    clear: 'Limpiar',
    save_and_other: "Guardar y adicionar otro",
    add_social_media: "Nueva Red Social",
    use_user: "el usuario esta en uso, no puede ser eliminado",
    max_size_avatar: "el avatar no debe exceder 1 MB",

    // ------------- MSG ----------------
    save_success: "¡Datos guardados exitosamente!",
    delete_success: "¡Datos eliminados exitosamente!",
    confirm_: "Confirmar",

    delete_confirm_role: "Seguro que desea realizar el Rol ?",
    delete_confirm_user: "Seguro que desea realizar el Usuario ?",
    delete_confirm_area: "Seguro que desea realizar el Area ?",
    delete_confirm_holiday: "Seguro que desea realizar el Día Feriado ?",
    pass_not_matches: "No cohinciden las contraseñas",

    repite_no: "La posición está en uso",
    invalida_no: "posición formato no aceptado, debe ser (1...99, N) y no (P1C, P2C, V)",
    not_vac: "no acepta formato V o Vac",
    noedit_pxc: "P1C, P2C y Vacaciones no pueden ser editados",
    noedit_change: "no se puede editar, vaya a Calendario Completo",
    email_success: "correos enviados satisfactoriamente",
    // -------------------- Daily Work Schedule --------------------

    actions: "acciones",
    print: "Imprimir",
    notify: "Notificación",
    assignment: "Asignación...",
    location: "localización",
    drag_drop: "Activar arrastrar y soltar",
    drag_drop_off: "Desactivar arrastrar y soltar",
    autonumber: "click para reasignar números",
    today: "Hoy",

    // area
    manage_area: "Gestionar área",
    new_area: "Crear Area",
    edit_area: "Editar Area",
    view_area: "Ver área",
    close_view_area: "Cerrar vista de Area",
    use_area: "Esta área está en uso por el horario de trabajo diario",
    description: "Descripción",
    show: "mostrar",

    alert: "Alerta",
    new_alert: "Nueva Alerta",
    send_email_text: "Enviar email notificando la actualización del Daily Work Schedule",

    // -------------------- Vacations --------------------
    request: 'Solicitud',
    from: 'Desde',
    to: 'Hasta',
    meeting_name: 'Nombre Mitin',
    meeting_location: 'Ubicación Mitin',
    vacation: 'Vacaciones',
    meeting: 'Mitin',
    type_request: 'Tipo de solicitud',
    new_request: 'Enviar Nueva Solicitud',
    period_request: 'Período Solicitado',
    data_submitted: 'Envío de Datos',
    comments: 'Comentarios',
    read_more: 'Leer más',
    staff_type: 'Tipo de Staff',
    select_user: 'Seleccione el usuario',
    aler_msg_dashboard: "una nueva solicitud de vacaciones ha sido enviada, ir a",
    wrong_request_date: "no puede solicitar vacaciones en periodos pasados",
    search_request: "Buscar en Historial de Solicitudes",

    //STATES
    New: 'Nuevo',
    Approved: 'Aprovado',
    Pending: 'Pendiente',
    Denied: 'Denegado',
    Conflict: 'Conflicto',

    // -------------------- Comprehensive --------------------
    cancel_change: 'Cancelar Cambio',
}