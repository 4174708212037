<template>
  <v-navigation-drawer app temporary :value="drawer" @input="changeNavegator">
    <v-sheet dense nav class="d-flex justify-space-between pa-5">
      <v-spacer></v-spacer>
      <!-- <lang-select></lang-select> -->
    </v-sheet>

    <v-divider></v-divider>

    <!-- menu -->
    <v-list dense nav>
      <v-list-group
        v-for="(section, index) in this.loadingSections()"
        :key="index"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="section.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <!-- list items -->
        <v-list-item
          class="pl-5"
          v-for="(option, indexI) in section.listOptions"
          :key="indexI"
          :to="option.url_link"
        >
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <v-img
                class="mr-3"
                max-width="36"
                height="36"
                :src="get_src(option.img_src)"
                contain
              ></v-img>
              {{ option.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
// import LangSelect from "../Atoms/LangSelect";
import { mapMutations, mapState } from "vuex";
import OptionsConfigMixin from "../../Dashboard/OptionsConfigMixin";

export default {
  // components: { LangSelect },
  mixins: [OptionsConfigMixin],
  data: () => ({
    groups: ["Schedules", "Admin Maintenance", "Staff Stack"],
    options: ["option 1", "option 2", "option 3"]
  }),
  computed: {
    ...mapState(["drawer"])
  },
  methods: {
    ...mapMutations(["setDrawer"]),
    changeNavegator(value) {
      this.setDrawer(value);
    }
  }
};
</script>