import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login.vue";
import routesAdmin from './modules/admin';
import routesSchedule from './modules/schedules';
import routesVacation from './modules/vacation';
import routesComprehensive from './modules/comprehensive';
import routesMonthly from './modules/monthly';
import auth from '@/utils/auth';
import store from '../store/index';

// resolucion del error de navegacion de Vue-router
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }
    return Promise.reject(err)
  })
}
//   fin de la resoucion del error   

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false }
  },
  // reset password
  {
    name: 'reset_password_user',
    path: '/reset-password-user/:uid/:token',
    component: () => import('@/views/ResetPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    name: 'img-test',
    path: '/img-test',
    component: () => import('@/components/ImgTest.vue'),
    meta: { requiresAuth: false }
  },
  {
    name: 'reset_password_success',
    path: '/reset-password-success/',
    component: () => import('@/views/RessetPasswordSuccess.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/components/Base/Pages/Page404.vue'),
    meta: { requiresAuth: false }
  },

  // print Daily Work Schedule
  {
    name: 'print_daily_work_schedule',
    path: `/print-daily-work-schedule/:date_time`,
    component: () => import('@/components/Schedules/DailyWorkSchedule/Print.vue'),
    meta: { requiresAuth: true, permission: `print_daily_work_schedule` } // campo para validar el permiso de los usuarios
  },
  // print Calendar Vacation Tracker
  {
    name: 'print_calendar_vrequest_tracker',
    path: `/print-calendar-request-tracker/`,
    component: () => import('@/components/Vacation/RequestTracker/PrintCalendar.vue'),
    meta: { requiresAuth: true /*, permission: `print_daily_work_schedule`  */ } // campo para validar el permiso de los usuarios
  },
  {
    path: '/dashboard',
    component: () => import('@/views/BaseDashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        name: 'dashboard',
        path: '/',
        component: () => import('@/components/Dashboard/Dashboard.vue'),
      },

      // Roles 
      {
        name: 'roles',
        path: '/roles/',
        component: () => import('@/components/AdminMaintenance/Roles/Page.vue'),
        meta: { permission: 'view_group' }, // permission: '***' => codename del permiso en la seguridad Django
      },
      {
        name: 'roles-add',
        path: '/roles-add/',
        props: { edit_mode: false },
        component: () => import('@/components/AdminMaintenance/Roles/PageManage.vue'),
        meta: { permission: 'add_group' },
      },
      {
        name: 'roles-edit',
        path: '/roles-edit/:id',
        props: { edit_mode: true },
        component: () => import('@/components/AdminMaintenance/Roles/PageManage.vue'),
        meta: { permission: 'change_group' },
      },

      // QGenda
      {
        name: 'qgenda',
        path: '/qgenda/',
        component: () => import('@/components/AdminMaintenance/Qgenda/QgendaPage.vue'),
        // meta: { permission: 'qgenda_import' },  crearlo en django
      },

      // User Profile
      {
        name: 'user_profile',
        path: '/user-profile/',
        component: () => import('@/components/Profile/Page.vue'),
      },
      // Employee Directory
      {
        name: 'employee_directory',
        path: '/employee_directory/',
        component: () => import('@/components/EmployeeDirectory/Page.vue'),
        // meta: { permission: `view_employee_directory` } // campo para validar el permiso de los usuarios
        // ****OJO**** el permiso utilizado no funciona se debe cambiar de otra forma creando un modelo en BD 
        // ahora mismo se dejo el acceso a todos los usuarios
      },

      ...routesAdmin(),
      ...routesSchedule(),
      ...routesVacation(),
      ...routesComprehensive(),
      ...routesMonthly()


    ]
  }
]


const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // desplasamiento del scroll al cambia de ruta
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 *  middleware que verifica si se tiene acceso a la ruta
 *  primero - que la ruta @param to y sus hijas cumplan con la condicion `requireAuth`:boolean
 *  segundo - que el rol del ususario autenticado este dentro de la lista de `rolesAuthCan`:[]
 * */
router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {

    // this route requires auth, check if logged in
    // 1) verificar si la ruta requiere login
    if (!auth.isLogin()) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      // 2) si esta autenticado. se verifica que temga permiso a la ruta solicitada
      // y se contruye la seguridad con los permisos del los role's      
      if (store.state.auth.userLogin)
        if (isAuthorizedUser(to.meta.permission))
          return next()
        else
          return next({ path: '/' })
      else {
        // F5 - para actualizar la app
        // 3) en caso de que no este el usuario en el store lo carga desde el backend, y al paso (2)
        store.dispatch("auth/loadDataToken")
          .then(() => {
            if (isAuthorizedUser(to.meta.permission))
              return next()
            else
              // al-> 2) si esta autenticado. se verifica que temga permiso a la ruta solicitada
              // se contruye la seguridad con los permisos del los role's
              return next({ path: '/',/*  query: { redirect: to.fullPath }  */ })

          })
      }
    }

  } else if (to.matched.length) {
    next()
  }
})

/**
 * comprueba si un @param permisssion se encuentra en la lista de permisos del usuario logeado
 * y retorna verdadero si el user cuenta con el permiso
 * @returns Boolen
 * @param {String} permission describe el permiso como el codename que se va a comprobar
 */
function isAuthorizedUser(permission) {
  const user = store.state.auth.userLogin

  //super user puede acceder a todas las rutas
  if (user.is_superuser) return true

  // permission - undefined puede acceder a la ruta
  if (!permission) return true

  // acceso a a las rutas por los grupos  
  if (!user.groups.length) return false

  const allpermissions = user.groups[0].permissions

  if (typeof permission == 'string')
    return allpermissions.some(e => e.codename == permission)
  else
    // arreglo de permisos
    return allpermissions.some(e => permission.some(s => s == e.codename))

}

export default router