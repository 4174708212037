<template>
  <v-menu
    v-model="menuAlert"
    :close-on-content-click="false"
    offset-y
    nudge-bottom="5"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mx-2"
        icon
        outlined
        color="grey darken-2"
        fab
        x-small
      >
        <v-badge
          v-model="vad"
          v-if="count__alerts"
          bordered
          color="error"
          :content="count__alerts"
          left
        >
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
        <v-icon v-else>mdi-bell-outline</v-icon>
      </v-btn>
    </template>

    <v-card width="344">
      <v-card-text class="text-center">
        <v-alert
          dense
          v-for="(item, index) in alerts"
          :key="index"
          icon="mdi-alert-decagram-outline"
          border="left"
          class="py-1 px-2 text-left text-caption mb-1"          
          color="warning"
          colored-border
          elevation="3"
        >
          <div v-html="item"></div>
        </v-alert>
        <v-btn outlined @click="clearAlerts" small class="mt-10" block
          ><v-icon left>mdi-broom</v-icon>Clear all
        </v-btn>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      vad: true,
      menuAlert: false
    };
  },

  computed: {
    ...mapState(["alerts"]),
    ...mapGetters(["count__alerts"])
  },

  methods:{
    ...mapMutations(["clearAlerts"])
  }
};
</script>