import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import en from 'vuetify/es5/locale/en';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { en, es },
        current: process.env.VUE_APP_I18N_LOCALE || "en",
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#1976D2',
                secondary: '#03A9F4', //blue darken-2
                accent: '#1ECDDE',//blue accent-1

                error: '#FF5252',//red accent-2
                info: '#2196F3', //blue 
                success: '#4CAF50',//green 
                warning: '#FFC107',//amber
                text_primary: '#707070'
            },
        }
    },
    icons: {
        iconfont: 'mdi', // default
        values: {
            edit: 'mdi-pencil',
            add_circle: 'mdi-plus-circle-outline',
            add: 'mdi-plus',
            delete: 'mdi-delete',
            close: 'mdi-close',
            checks: 'mdi-check-box-multiple-outline',
            find: "mdi-magnify",
            clear: "mdi-broom",

            // Vacations
            meeting: 'mdi-account-voice',
            vacation: 'mdi-beach',
            print: 'mdi-printer',
        }
    }
});
