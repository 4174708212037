<template>
  <v-card style="background-color: transparent !important" width="390">
    <v-card-text class="opacity-bg" style="height:316px;">
      <v-form class="mt-15" ref="form">
        <v-text-field
          prepend-inner-icon="mdi-email-outline"
          class="mt-6"
          :label="$t('email')"
          :rules="rules_user"
          outlined
          name="email"
          v-model="email"
        >
          <template v-slot:message="{ message }">
            {{ $i18n.t(message) }}
          </template>
        </v-text-field>

        <!-- v-button -->
        <v-btn :loading="loading" @click="onReset" block color="primary" dark
          ><v-icon left>mdi-lock-reset</v-icon> {{ $t("reset_pass") }}</v-btn
        >
        <v-btn
          :disabled="loading"
          @click="$emit('cancel')"
          block
          text
          class="mt-5"
          >{{ $t("cancel") }}</v-btn
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import { loginMixin } from "@/mixins/rules_mixin";

export default {
  mixins: [loginMixin],
  data: () => ({
    loading: false,
    email: ""
  }),

  methods: {
    ...mapMutations(["showNotify"]),
    ...mapActions("auth", ["login"]),
    async onReset() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          await this.axios.post("retrieve-password/", {
            email: this.email
          });
          this.showNotify({
            msg: this.$t("reset_pass_confirm"),
            time: 7000,
            icon: "mdi-shield-alert"
          });
        } catch (error) {
          this.showNotify({
            msg: this.$t("reset_pass_error"),
            time: 7000,
            capitalize: false,
            color: "error",
            icon: "mdi-shield-alert"
          });
        }
        this.loading = false;
        this.$emit("cancel");
      }
    }
  }
};
</script>
<style scoped>
.opacity-bg {
  background-color: rgba(232, 232, 232, 0.8);
}
</style>