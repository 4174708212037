<template>
  <!-- <v-toolbar > -->
  <v-app-bar app fixed color="white">
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      @click.stop="onDrwaser"
    ></v-app-bar-nav-icon>
    <logo-mbaa isClicked></logo-mbaa>
    <v-spacer></v-spacer>

    <!-- Menu -->

    <v-menu
      offset-y
      nudge-bottom="5"
      v-for="(section, index) in this.loadingSections()"
      :key="index"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          class="text-capitalize subtitle-1 font-weight-light px-3 hidden-md-and-down"
          v-bind="attrs"
          v-on="on"
        >
          {{ section.title }}
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-card min-width="450" max-width="600">
        <!-- <v-list> -->
        <v-row no-gutters>
          <v-col
            cols="6"
            v-for="(option, indexI) in section.listOptions"
            :key="indexI"
          >
            <v-hover v-slot="{ hover }" open-delay="200">
              <router-link
                :class="{ 'grey lighten-2': hover }"
                class="d-flex justify-start align-center text-capitalize subtitle-1 font-weight-light pa-2 grey--text text--darken-2"
                style="text-decoration: none;"
                :to="option.url_link"
              >
                <!-- <div > -->
                <v-img
                  class="mr-3"
                  max-width="36"
                  height="36"
                  :src="get_src(option.img_src)"
                  contain
                >
                </v-img>
                {{ option.name }}
                <!-- </div> -->
              </router-link>
            </v-hover>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
    <!-- <lang-select v-if="!$vuetify.breakpoint.mdAndDown"></lang-select> -->

    <!-- Alert -->
    <base-notify />
    <!-- User Menu -->
    <base-user-menu />
  </v-app-bar>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import LogoMbaa from "../../Home/LogoMbaa";
// import LangSelect from "../Atoms/LangSelect";
import OptionsConfigMixin from "../../Dashboard/OptionsConfigMixin";

export default {
  components: { LogoMbaa/* , LangSelect */ },
  mixins: [OptionsConfigMixin],
  data() {
    return {};
  },
  computed: {
    ...mapState(["drawer"]),
    ...mapState("auth", ["userLogin"])
  },
  methods: {
    ...mapMutations(["invertDrawer"]),
    onDrwaser() {
      this.invertDrawer();
    }    
  }
};
</script>