import Vue from 'vue'
import Vuex from 'vuex'
import { make } from '../utils/core';

import auth from './modules/auth';
import admin_maintenance from './modules/admin_maintenance';
import schedules from './modules/schedules';
import vacation from './modules/vacation';
import comprehensive from './modules/comprehensive';
import monthly from './modules/monthly';

Vue.use(Vuex)

function generateIcon(color) {
  switch (color) {
    case 'error':
      return 'mdi-close-circle-outline'
    case 'warning':
      return 'mdi-alert-circle-outline'
    default:
      return 'mdi-check-circle-outline'
  }
}

// ---------------- State ----------------
const state = {

  drawer: false,
  notify: {
    visible: false,
    msg: '...',
    capitalize: true,
    color: 'success',
    time: 2000
  },
  /** Alertas temporales del sistema */
  alerts: []

}

// ---------------- mutations ----------------
const mutations = {
  ...make.mutations(state),

  /**
    * mutation para mostrar un `<v-snackbar>` con un mensaje global del sistema
    * @param {*} state 
    * @param {{visible:boolean, msg:String, color:String, time:Number}} values - **Object** de configuracion para la
    * notificación
    */
  showNotify(state, values) {

    state.notify = Object.assign({}, {
      visible: true,
      msg: values.msg || '...',
      color: values.color || 'success',
      capitalize: Object.prototype.hasOwnProperty.call(values, 'capitalize') ? values.capitalize : true,
      time: values.time || 2000,
      icon: values.icon || generateIcon(values.color) // mdi-close-circle-outline   mdi-alert-circle-outline
    })
  },

  closeNotify(state) {
    state.notify.visible = false
  },

  /**
     * Errores que se genenran desde la carga de los datos del Daily Work Schedeule,
     * **NO QGebdaID** y **Unknow Task abb** formato `{ "name", "date", "description"}`
     * @param {*} errors 
     */
  setErrorsScheduleStaff(state, errors) {
    errors.forEach((el) => {
      let text = ''
      if (el.name == 'NO QGebdaID')
        text = 'not assigned to a physician'
      else
        text = 'review data!'
      state.alerts.push(`
      <span class="mb-0 font-weight-black">${el.name}: </span>
      <strong>(${el.description}) </strong> ${text}`)
    })
  },

  setErrorComprehensive(state, error) {
    state.alerts.push(`
    <span class="mb-0 font-weight-black">${error.name}: </span>
    <strong>(${error.description}) </strong> ${error.text}`)
  },

  clearAlerts(state) {
    state.alerts = []
  }
}

// ---------------- actions ----------------
const actions = {
}

// ---------------- modules ----------------
const modules = {
  auth, admin_maintenance, schedules, vacation, comprehensive, monthly
}

// ---------------- getters ----------------
const getters = {
  drawer: (state) => {
    return state.drawer;
  },

  count__alerts: (state) => {
    return state.alerts.length
  }
}
export default new Vuex.Store({
  state, mutations, actions, getters, modules
})
