<template>
  <v-footer class="pa-0">
    <v-img :height="heightFooter" src="@/assets/background-footer.png">
      <v-row no-gutters>
        <!-- <v-col cols="12" md="5" lg="4" class="text-center pa-3">
          <div class="text-h5 mb-8" style="color: #03a9f4">Calendar</div>
          <v-date-picker no-title scrollable> </v-date-picker>
        </v-col> -->
        <v-col cols="12" md="1">
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            vertical
            style="color: #03a9f4"
            dark
          ></v-divider>
          <v-divider v-else style="color: #03a9f4" dark></v-divider>
        </v-col>
        <v-col class="d-flex flex-column" cols="12" md="6" lg="7">
          <div
            class="d-flex align-center justify-center justify-sm-start pt-3 mb-12 pl-3"
          >
            <v-img src="@/assets/logo.svg" max-width="80" contain></v-img>
            <div class="ml-5">
              <h1 class="display-1 primary--text">MBAA</h1>
              <p
                class="subtitle-1 mb-0 d-none d-sm-flex"
                style="color: #dedede"
              >
                Miami Beach Anesthesiology Associates
              </p>
            </div>
          </div>
          <div
            class="mt-2 ml-sm-6 d-xs-block d-sm-flex text-center"
            style="color: #dedede"
          >
            <v-icon color="#DEDEDE" left>mdi-map-marker</v-icon>
            <!-- <div>Miami Beach, United States of American</div> -->
            <div>
              Mount Sinai Medical Center 4300 Alton Rd. Miami Beach, Fl 33140
            </div>
          </div>
          <!-- <div
            class="mt-2 ml-sm-6 d-xs-block d-sm-flex text-center"
            style="color: #dedede"
          >
            <v-icon color="#DEDEDE" left>mdi-email-outline</v-icon>
            <div>mbaa@gmail.com, soporte@gmail.com</div>
          </div>
          <div
            class="mt-2 ml-sm-6 d-xs-block d-sm-flex text-center"
            style="color: #dedede"
          >
            <v-icon color="#DEDEDE" left>mdi-phone-classic</v-icon>
            <div>+1(745) 7521845, +1(745) 7521845</div>
          </div> -->
          <div
            style="color: #dedede; display: none !important;"
            class=" font-weight-light mb-2 mt-2 ml-8 d-xs-block d-sm-flex text-center"
          >
            <p class="mb-0">
              Icons: <a href="https://icons8.com/">https://icons8.com</a>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-img>

    <div
      style="width: 100%;"
      class="pl-4 text-caption blue darken-4 white--text font-weight-light"
    >
      <p class="mb-0">© Copyright MBAA. All Rights Reserved</p>
    </div>
  </v-footer>
</template>
<script>
export default {
  data: () => ({
    vst_version: 1
  }),
  computed: {
    heightFooter() {
      if (this.$vuetify.breakpoint.xs) return 260;
      return this.$vuetify.breakpoint.mdAndUp ? 260 : 260;
    }
  }
};
</script>
<style scoped>
.back {
  background-color: #0e2344;
}
.tit {
  font-weight: 300;
}
</style>