import axios from 'axios'
import { make } from '@/utils/core.js';
import { OB, VAC, HOURS, clone } from '../../utils/core';
import _ from 'lodash';
import { DateTime } from "luxon";

const state = {
    totals: [],
    /*  [{
            id_user,
            name,
            list_hours:[
                {   day,
                    hours,
                    id,
                    location
                    special_rotation
                }
            ]}
        ]               */
    list_monthly: [],
    origin_list_monthly: [],

    // filter 
    search: "",
    hours_filters: [],
    srna_level_filter: [],
    inactive: false,
}

const mutations = {
    ...make.mutations(state),

    /**
     * Calcular los totales para todos los dias
     * @param {*} state 
     */
    calcTotals(state) {
        state.totals = []
        for (let item of state.list_monthly) {
            for (let itemMonth of item.list_hours) {
                let dayTotal = state.totals.find(e => e.day == itemMonth.day)

                // let hours = parseInt(itemMonth.hours)
                if (!['V'].some(el => el == itemMonth.hours)) {
                    if (dayTotal) dayTotal.count++
                    else state.totals.push({ day: itemMonth.day, count: 1 })
                }
            }
        }
    },

    countTotals(state, item) {
        let dayTotal = state.totals.find(e => e.day == item.day)
        let hours = parseInt(item.hours)
        if (hours) {
            if (dayTotal) dayTotal.hours += hours
            else state.totals.push(Object.assign({}, item))
        }
    },

    /**
     * Manage List Hours
     */
    addHoursList(state, data) {
        let monthly = state.list_monthly.find(e => e.id_user == data.id_user);

        if (monthly) {
            const day = DateTime.fromISO(data.date).toFormat("dd");
            monthly.list_hours.push({
                day: day,
                hours: data.no,
                id: data.id,
                location: data.location,
                special_rotation: data.special_rotation,
                hrt: data.hrt
            });

        }
    },

    editHoursList(state, data) {
        let monthly = state.list_monthly.find(e => e.id_user == data.id_user);
        if (monthly) {
            const day = DateTime.fromISO(data.date).toFormat("dd");
            let itemHour = monthly.list_hours.find(e => e.day == day);

            itemHour.day = day;
            itemHour.hours = data.no;
            itemHour.id = data.id;
            itemHour.location = data.location;
            itemHour.special_rotation = data.special_rotation;
            itemHour.hrt = data.hrt;

            // actualizar el Heart
            if (data.hrt) {
                let finded = state.list_monthly.find(e =>
                    e.id_user != data.id_user &&
                    e.list_hours.some(el => el.hrt) && e.user_type == data.sch_type)
                if (finded) finded.list_hours.find(el => el.hrt).hrt = false

            }
        }
    },
    deleteHoursList(state, editItem) {
        let monthly = state.list_monthly.find(
            e => e.id_user == editItem.id_user
        );

        if (monthly) {
            const day = DateTime.fromISO(editItem.date).toFormat("dd");

            const index = monthly.list_hours.findIndex(e => e.day == day);
            monthly.list_hours.splice(index, 1);
        }
    }
}

// ************** Actions ******************
const actions = {

    // --------------- Holidays -----------------
    async loadMonthly({ commit }, params) {
        const { year, month, type } = params
        const res = await axios.get(`monthly/get-data/${type}/${year}/${month}/`)
        commit('setListMonthly', res.data)
        commit('setOriginListMonthly', res.data)
    },
}


// ************** Gerrters ******************
const getters = {

    /**
     * 
    */
    filterListMonthly(state) {

        // copia deep con los dos métodos de lodash, uno para shallow y otro para deep
        let list_return = _.cloneDeep(state.list_monthly)

        // Search
        if (state.search)
            list_return = list_return.filter(e => e.name.toLowerCase().includes(state.search.toLowerCase()))

        // Search
        if (state.srna_level_filter.length)
            list_return = list_return.filter(e => state.srna_level_filter.some(el => el == e.user_type))

        // Hours Filter
        if (state.hours_filters.length) {

            for (const item of list_return) {

                item.list_hours = clone(item.list_hours.filter(e => {
                    // hours
                    if (state.hours_filters.some(i => i == HOURS) && parseInt(e.hours))
                        return true
                    // OB
                    if (state.hours_filters.some(i => i == OB) && e.hours == OB)
                        return true

                    // Vac.
                    if (state.hours_filters.some(i => i == VAC) && e.hours == VAC)
                        return true

                    return false

                }))

            }
        }

        // Inactive -

        if (!state.inactive)
            list_return = list_return.filter(e => e.is_active == true)

        return list_return
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}