<template>
  <!-- baner-litle -->
  <div style="position: relative">
    <v-img
      min-height="120"
      src="@/assets/background-lite.png"
      class="d-flex justify-center align-center"
    >
      <div style="height: 100%" class="d-flex justify-center align-center title-format">
        <slot name="title"></slot>
      </div>
    </v-img>
    <div v-if="showAvatar" class="mt-n9 mt-sm-n11 mt-lg-n16 d-flex">
      <div class="mx-auto" style="position: relative">
        <v-avatar
          :size="avatarSize"
          color="white"
          style="border: 3px solid #1ecbdb !important; z-index: 100;"
        >
          <slot v-if="!seletAvatar"></slot>
          <!-- <v-img v-else :src="urlAvatar" alt="Group image" /> -->
          <croppa
            v-else
            v-model="myCroppa"
            :width="avatarSize"
            :height="avatarSize"
            :initial-image="initialImage"
            :zoom-speed="10"
            @file-choose="fileChoose"
            @move="editImage()"
            @zoom="editImage()"
          ></croppa>
        </v-avatar>
        <v-btn
          v-if="seletAvatar"
          style="position: absolute; z-index: 200;"
          :class="btnConfig"
          fab
          color="primary"
          :x-small="x_small"
          :small="!x_small"
          @click="onSelectAvatar"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    showAvatar: { type: Boolean },
    seletAvatar: { type: Boolean },
    id_user: { type: String, default: "0" }
  },
  data() {
    return {
      myCroppa: {},
      initialImage: null
    };
  },
  computed: {
    ...mapState("auth", ["userLogin"]),
    avatarSize() {
      if (this.$vuetify.breakpoint.xs) return 80;
      if (this.$vuetify.breakpoint.mdAndDown) return 90; // sm and md
      if (this.$vuetify.breakpoint.lgAndUp) return 125; // lg and up
      return 125;
    },
    btnConfig() {
      if (this.$vuetify.breakpoint.xs) return "size-by-xs";
      if (this.$vuetify.breakpoint.mdAndDown) return "size-by-md"; // sm and md
      return "size-by-lg"; // lg and up
    },
    x_small() {
      if (this.$vuetify.breakpoint.lgAndUp) return false;
      else return true;
    }
  },

  destroyed() {
    this.clearAvatar();
  },

  methods: {
    ...mapMutations(["showNotify"]),
    ...mapMutations("admin_maintenance", [
      "setImageAvatar",
      "setUrlAvatar",
      "clearAvatar",
      "setIsChangeAvatar"
    ]),

    onSelectAvatar() {
      this.myCroppa.chooseFile();
    },

    fileChoose() {
      this.setIsChangeAvatar(true);
    },

    editImage() {
      console.log("edicion!!!!");
      this.setIsChangeAvatar(true);
      this.generateDataUrl();
    },
    refreshImg(src) {
      var image = new Image();
      image.setAttribute("crossorigin", "anonymous");
      // image.crossOrigin = "use-credentials";

      image.src = src;
      this.initialImage = image;
      this.myCroppa.refresh();
    },

    async generateDataUrl() {
      const blob = await this.myCroppa.promisedBlob("image/jpeg");
      var file = new File([blob], `avatar-${this.id_user}.png`);

      this.setImageAvatar(file);
      this.setUrlAvatar(URL.createObjectURL(file));
    }
  }
};
</script>
<style scoped>
.avatar-round-center {
  border: 3px solid #1ecbdb !important;
}

.size-by-xs {
  bottom: -3px;
  right: -5px;
}

.size-by-md {
  bottom: 0px;
  right: -5px;
}

.size-by-lg {
  bottom: 0px;
  right: 2px;
}

.title-format >>> h1{
  background: rgba(0, 114, 169, 0.4);
  color: white;
  padding: 0.2em;
  border-radius: 0.3em;
}
</style>