import { DateTime } from 'luxon';

/**
 * Configuración de las secciones del (dashboard | Menu | MenusNavegatorDrawer)  con las opciones de cada una
 * 
 * que se mostraran dependiendo de las rules|Ability cargadas para el usuario de los permisos 
 * asignados en los roles
 * 
 * {
        app_label: "core",          --> campo tabla BD (django_content_type)
        model: "qgenda",            --> campo tabla BD (django_content_type)

        name: "QGenda",             --> Title de la Opcion
        img_src: "Qgenda.png",      --> imagen que varga la opcion en el menu
        url_link: "/qgenda/"        --> url en el vueRouter
        noInSuper: true             --> NO MUESTA PARA EL SUPER_ADMIN, con solo definir la propiedad 
        canUsertype: []             --> [Array] de tipos de usuarios que pueden ver una Options ej. ['physician', 'crna']
    },
 *  
 */

export default [
    {
        title: "Schedules",
        listOptions: [
            {
                app_label: "schedule",
                model: "schedule",

                name: "Daily Work Schedule",
                img_src: "daily_work_schedule.png",
                url_link: "/daily-work-schedule/" + DateTime.now().toFormat('yyyy-MM-dd')
            },
            {
                app_label: "comprehensive",
                model: "changeschedulestaff",

                name: "On-Call Schedule",
                img_src: "comprehensive.png",
                url_link: "/comprehensive/" + DateTime.now().toFormat('yyyy-MM-dd')
            },
            {
                app_label: "holiday",
                model: "holiday",

                name: "Holidays",
                img_src: "holiday.png",
                url_link: "/holiday/" + DateTime.now().toFormat('yyyy')
            },
            {
                app_label: 'schedule', // no esta reprecentado en bd
                model: 'schedule',
                codename: "show_monthly_crna", //  casos de permisos 'show_monthly_crna'

                name: "CRNA monthly", // sin reprecentacion en base datos
                img_src: "crna-monthly.png",
                url_link: `/monthly-work-schedule/crna/${DateTime.now().toFormat('yyyy')}/${DateTime.now().toFormat('MM')}`,
                // canUsertype: ["crna"] //[Array] de tipos de usuarios que pueden ver una Options ej. ['physician', 'crna']
            },
            {
                app_label: 'schedule', // no esta reprecentado en bd
                model: "schedule",
                codename: "show_monthly_srna", //  casos de permisos 'show_monthly_srna'

                name: "SRNA monthly", // sin reprecentacion en base datos
                img_src: "srna-monthly.png",
                url_link: `/monthly-work-schedule/srna/${DateTime.now().toFormat('yyyy')}/${DateTime.now().toFormat('MM')}`,
                // canUsertype: ["srna"] //[Array] de tipos de usuarios que pueden ver una Options ej. ['physician', 'crna']
            },
            {
                app_label: 'schedule', // no esta reprecentado en bd
                model: 'schedule',
                codename: "show_monthly_resident", //  casos de permisos 'show_monthly_resident'

                name: "Resident monthly", // sin reprecentacion en base datos
                img_src: "resident-monthly.png",
                url_link: `/monthly-work-schedule/resident/${DateTime.now().toFormat('yyyy')}/${DateTime.now().toFormat('MM')}`,
                // canUsertype: ["resident"] //[Array] de tipos de usuarios que pueden ver una Options ej. ['physician', 'crna']
            },

        ]
    },
    {
        title: "Admin Maintenance",
        listOptions: [
            {
                app_label: "core",
                model: "qgenda",

                name: "QGenda",
                img_src: "Qgenda.png",
                url_link: "/qgenda/"
            },
            {
                app_label: "auth",
                model: "group",

                name: "Roles",
                img_src: "groups.png",
                url_link: "/roles/"
            },
            {
                app_label: "core",
                model: "physician",

                name: "Physician",
                img_src: "physician.png",
                url_link: "/physician/"
            },
            {
                app_label: "core",
                model: "crna",

                name: "CRNA",
                img_src: "crna.png",
                url_link: "/crna/"
            },
            {
                app_label: "core",
                model: "srna",

                name: "SRNA",
                img_src: "srna.png",
                url_link: "/srna/"
            },
            {
                app_label: "core",
                model: "resident",

                name: "Resident",
                img_src: "resident.png",
                url_link: "/resident/"
            },
            {
                app_label: 'core', // no esta reprecentado en bd
                model: 'administrativeassistant',

                name: "Admin. Assistant", // sin reprecentacion en base datos
                img_src: "administrativeassistant.png",
                url_link: "/administrativeassistant/"
            },
            {
                app_label: 'core', // no esta reprecentado en bd
                model: 'guest',

                name: "Guest", // sin reprecentacion en base datos
                img_src: "guest.png",
                url_link: "/guest/"
            },
            {
                app_label: 'vacation', // no esta reprecentado en bd
                model: 'vrequestadmin',

                name: "Vacation  Tracker", // sin reprecentacion en base datos
                img_src: "request-tracker-admin2.png",
                url_link: "/vacations-request-tracker/admin/"
            },
            {
                app_label: 'vacation', // no esta reprecentado en bd
                model: 'logsadmin',
                codename: "logs_admin_physician", //  casos de permisos 'show_monthly_resident'

                name: "Physician Logs", // sin reprecentacion en base datos
                img_src: "physician-logs.png",
                url_link: "/logs-admin/physician/"
            },
            {
                app_label: 'vacation', // no esta reprecentado en bd
                model: 'logsadmin',
                codename: "logs_admin_resident", //  casos de permisos 'show_monthly_resident'

                name: "Resident Logs", // sin reprecentacion en base datos
                img_src: "resident-logs.png",
                url_link: "/logs-admin/resident/"
            },
            {
                app_label: 'vacation', // no esta reprecentado en bd
                model: 'logsadmin',
                codename: "logs_admin_crna",

                name: "CRNAs Logs", // sin reprecentacion en base datos
                img_src: "crna-logs.png",
                url_link: "/logs-crna-admin/"
            },
            {
                app_label: 'core', // no esta reprecentado en bd
                model: null,
                onlyInSuper: true, // SOLO MUESTA PARA EL SUPER_ADMIN, con solo definir la propiedad 

                name: "Employee Directory", // sin reprecentacion en base datos
                img_src: "employee_directory.png",
                url_link: "/employee_directory/"
            },
        ]
    },


    {
        title: "Staff Tasks",
        listOptions: [
            {
                app_label: 'vacation', // no esta reprecentado en bd
                model: 'vrequest',

                name: "Request Vacation", // sin reprecentacion en base datos
                img_src: "vacations-request-tracker.png",
                url_link: "/vacations-request-tracker/",
                noInSuper: true // NO MUESTA PARA EL SUPER_ADMIN, con solo definir la propiedad 
            },
            {
                app_label: 'vacation', // no esta reprecentado en bd
                model: 'requestlogs',

                name: "My Vacation Logs", // sin reprecentacion en base datos
                img_src: "physician-logs.png",
                url_link: "/logs/physician/",
                noInSuper: true, // NO MUESTA PARA EL SUPER_ADMIN, con solo definir la propiedad 
                canUsertype: ["physician"] //[Array] de tipos de usuarios que pueden ver una Options ej. ['physician', 'crna']
            },
            {
                app_label: 'vacation', // no esta reprecentado en bd
                model: 'requestlogs',

                name: "My Vacation Logs", // sin reprecentacion en base datos
                img_src: "resident-logs.png",
                url_link: "/logs/resident/",
                noInSuper: true, // NO MUESTA PARA EL SUPER_ADMIN, con solo definir la propiedad 
                canUsertype: ["resident"] //[Array] de tipos de usuarios que pueden ver una Options ej. ['physician', 'crna']
            },
            {
                app_label: 'vacation', // no esta reprecentado en bd
                model: 'requestlogs',

                name: "My Vacation Logs", // sin reprecentacion en base datos
                img_src: "crna-logs.png",
                url_link: "/logs-crna/",
                noInSuper: true, // NO MUESTA PARA EL SUPER_ADMIN, con solo definir la propiedad 
                canUsertype: ["crna"] //[Array] de tipos de usuarios que pueden ver una Options ej. ['physician', 'crna']
            },
            {
                app_label: 'core', // no esta reprecentado en bd
                model: null,

                name: "Employee Directory", // sin reprecentacion en base datos
                img_src: "employee_directory.png",
                noInSuper: true, // NO MUESTA PARA EL SUPER_ADMIN, con solo definir la propiedad 
                url_link: "/employee_directory/"
            },
        ]
    }
]