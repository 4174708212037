import axios from 'axios'
import { make } from '@/utils/core.js';
import i18n from '@/plugins/i18n'
// import { getObjStatus } from '../../utils/core';
// import { DateTime } from "luxon";

const state = {
    list_comprehensive: [],
    holidays: [],
}

const mutations = {
    ...make.mutations(state),
}

// ************** Actions ******************
const actions = {

    loadComprehensive({ commit }, params) {
        const { month, year } = params
        axios.get(`comprehensive/calendar-by-date/${month}/${year}/`)
            .then(res => {
                commit('setListComprehensive', res.data)
            })
    },

    async ManageChangeScheduleStaff({ commit }, params) {
        try {
            const { editmode, id_change_schedule, data } = params
            if (editmode) await axios.put(`comprehensive/change-schedule-staff/${id_change_schedule}/`, data)
            else await axios.post("comprehensive/change-schedule-staff/", data)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },

    async deleteChangeScheduleStaff({ commit }, id) {
        try {
            await axios.delete(`comprehensive/change-schedule-staff/${id}/`)
            commit('showNotify', { msg: i18n.t('delete_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },

    // --------------- Holidays -----------------
    async loadHoliday({ commit }, year) {
        const res = await axios.get(`comprehensive/holidays/${year}/`)
        commit('setHolidays', res.data)
    },
    async addHoliday({ commit }, params) {
        try {
            const data = await axios.post(`comprehensive/holidays/${params.year}/`, params)
            commit('addHolidays', data.data)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async updateHoliday({ commit }, params) {
        try {
            const { id, data } = params
            const res = await axios.put(`comprehensive/holiday/${id}/`, data)
            if (res.data) {
                commit('updateHolidays', { id, data: res.data })
                commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            }
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async deleteHoliday({ commit }, id) {
        try {
            await axios.delete(`comprehensive/holiday/${id}/`)
            commit('deleteHolidays', { id })
            commit('showNotify', { msg: i18n.t('delete_success') }, { root: true })
        } catch (error) {
            commit('showNotify', { msg: 'error' }, { root: true })
        }
    },
}


// ************** Gerrters ******************
const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}