export default function () {
    // codigo dinamico aqui

    //array de retorno
    return [
        {
            name: 'daily_work_schedule',
            path: `/daily-work-schedule/:date_time`,
            component: () => import('@/components/Schedules/DailyWorkSchedule/PageDailyWork.vue'),
            meta: { permission: `view_schedule` } // campo para validar el permiso de los usuarios
        },
        {
            name: 'manage_area',
            path: `/manage-area/`,
            component: () => import('@/components/Schedules/DailyWorkSchedule/PageArea.vue'),
            meta: { permission: `view_area` } // campo para validar el permiso de los usuarios
        }        
    ]
}