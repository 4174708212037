export default function () {
    // codigo dinamico aqui

    //array de retorno
    return [
        {
            name: 'monthly_work_schedule',
            path: `/monthly-work-schedule/:type/:year/:month/`,
            component: () => import('@/components/MonthlyWorkSchedule/Page.vue'),
            meta: { permission: [`show_monthly_srna`, 'show_monthly_crna', 'show_monthly_resident'] } // campo para validar el permiso de los usuarios
        },
        // holiday
        // {
        //     name: 'holiday',
        //     path: `/holiday/:year/`,
        //     component: () => import('@/components/Holiday/Page.vue'),
        //     meta: { permission: `view_holiday` } // campo para validar el permiso de los usuarios
        // },
        // {
        //     name: 'add-holiday',
        //     path: `/add-holiday/`,
        //     component: () => import('@/components/Holiday/PageEdit.vue'),
        //     meta: { permission: `add_holiday` } // campo para validar el permiso de los usuarios
        // },
        // {
        //     name: 'edit-holiday',
        //     path: `/edit-holiday/:id/`,
        //     component: () => import('@/components/Holiday/PageEdit.vue'),
        //     meta: { permission: `change_holiday` } // campo para validar el permiso de los usuarios
        // },
    ]
}