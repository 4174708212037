import axios from 'axios'
import { make } from '@/utils/core.js';
import i18n from '@/plugins/i18n'
const state = {
    roles: [],
    permissions: [],
    listUserType: [],
    list_directory: [],

    isChangeAvatar: false,
    imageAvatar: null,
    urlAvatar: null
}

const mutations = {
    ...make.mutations(state),

    setImageAvatar(state, data) {
        state.imageAvatar = data
        // state.urlAvatar = data.url;
    },
    setUrlAvatar(state, data) {
        // state.imageAvatar = data.image
        state.urlAvatar = data;
    },
    setUrlAvatarLocal(state, data) {
        // state.imageAvatar = data.image
        state.urlAvatar = `http://127.0.0.1:8000${data}`;

    },

    /**
     * Cuando se sale de las vistas dodne esta precente el avatar destruirlo para que no 
     * se mantenga en memoria, y ocurra algun error no deseado
     */
    clearAvatar(state) {
        state.imageAvatar = null
        state.urlAvatar = null
    },
}

// ************** Actions ******************
const actions = {

    // ---------- AVATAR ------------

    /**
     * Cargar el avatar desde la Django para el usuario @param user
     */
    loadAvatar({ commit }, user) {
        const res = axios.get(`/avatart/${user}/`)
        commit('' + res)
    },

    deleteAvatar() { },




    // ---------- CRUD Roles ------------

    async loadRoles({ commit }) {
        const res = await axios.get('/groups/')
        commit('setRoles', res.data)
    },
    async loadPermissions({ commit }) {
        const res = await axios.get('/permission/')
        commit('setPermissions', res.data)
    },
    async addRole({ commit }, role) {
        try {
            const data = await axios.post("groups/", role)
            commit('addRoles', data.data)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                // { msg: `create error`, color: 'error' },
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async updateRole({ commit }, role) {
        try {
            // retorna 1 o 0
            const res = await axios.put(`/groups/${role.id}/`, { ...role.data })
            console.log(res);
            if (res.data) {
                commit('updateRoles', { id: role.id, data: res.data })
                commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            }
            return true
        } catch (error) {
            console.log(error);
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async deleteRole({ commit }, id) {
        try {
            await axios.delete(`/groups/${id}/`)
            commit('deleteRoles', { id })
            commit('showNotify', { msg: i18n.t('delete_success') }, { root: true })
        } catch (error) {
            commit('showNotify', { msg: 'error' }, { root: true })
        }
    },

    // ---------- QGenda ------------

    async uploadqgenda({ commit }, data) {
        try {
            await axios.post(`/upload-qgenda/`, data)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
        } catch {
            commit('showNotify',
                { msg: 'Error to load data from QGenda !', color: 'error' },
                { root: true })
        }
    },
    // ---------- CRUD DataUserType ------------

    async loadDataUserType({ commit }, user_type) {
        const res = await axios.get(`/${user_type}/`)
        commit('setListUserType', res.data)
    },
    async addDataUserType({ commit, getters }, data) {
        try {
            const { user_type, user } = data

            // FormData
            let fd = new FormData();
            fd.append("avatar", getters.getIsChangeAvatar ? getters.getimageAvatar : "");
            const list = Object.entries(user)
            for (const one of list) {
                fd.append(one[0], one[1]);
            }
            const res = await axios.post(`/${user_type}/`, fd)
            commit('addListUserType', res.data)
            commit('setIsChangeAvatar', false)
            commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            return true
        } catch (error) {
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async updateDataUserType({ commit, getters }, data) {
        try {
            const { user_type, user } = data

            // FormData
            let fd = new FormData();
            if (getters.getIsChangeAvatar)
                fd.append("avatar", getters.getimageAvatar);
            const list = Object.entries(user.data)
            for (const one of list) {
                fd.append(one[0], one[1]);
            }
            const res = await axios.put(`/${user_type}/${user.id}/`, fd)
            // const res = await axios.put(`/${user_type}/${user.id}/`, { ...user.data })
            if (res.data) {
                commit('setIsChangeAvatar', false)
                commit('updateListUserType', { id: user.id, data: res.data })
                commit('showNotify', { msg: i18n.t('save_success') }, { root: true })
            }
            return true
        } catch (error) {
            console.log(error);
            commit('showNotify',
                { msg: Object.entries(error.response.data)[0][1][0], color: 'error' },
                { root: true })
            return false
        }
    },
    async deleteDataUserType({ commit }, data) {
        try {
            const { user_type, id } = data
            await axios.delete(`/${user_type}/${id}/`)
            commit('deleteListUserType', { id })
            commit('showNotify', { msg: i18n.t('delete_success') }, { root: true })
        } catch (error) {
            commit('showNotify', { msg: i18n.t('use_user'), color: "error" }, { root: true })
        }
    },

    // ---------- Employee Directory ------------
    async loadListDirectory({ commit }) {
        const res = await axios.get(`/load-directory/`)
        commit('setListDirectory', res.data)
    },
}

// ************** Gerrters ******************

const getters = {
    list_permissions(state) {
        return state.permissions.map(e => ({ id: e.id, name: `${e.content_type.model} | ${e.name} ` }))
    },

    orderListUserType(state) {
        return state.listUserType.sort((a, b) => {
            if (a.last_name > b.last_name) {
                return 1;
            }
            if (a.last_name < b.last_name) {
                return -1;
            }
            return 0;
        })
    },

    orderRoles(state) {
        return state.roles.sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        })
    },

    getimageAvatar(state) {
        return state.imageAvatar
    },

    getIsChangeAvatar(state) {
        return state.isChangeAvatar
    },

    listOrderDirectory(state) {
        return state.list_directory.sort((a, b) => {
            const nameA = a.last_name.toUpperCase();
            const nameB = b.last_name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}