<template>
  <v-menu offset-y nudge-bottom="5">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="{ 'absolute-lang': absolute }"
        text
        fab
        class="px-2"
        x-small
        v-bind="attrs"
        v-on="on"
      >
        <v-img :src="system_lang" alt="usa imagen" />
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        @click="changeLang(item.value)"
        v-for="(item, index) in langs"
        :key="index"
      >
        <img :src="getImgUrl(item.value)" alt="usa imagen" class="mr-2" />
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    absolute: {
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    langs: [],
    CURRENT_LANG: "currentLanguage"
  }),
  created() {
    this.langs = this.$i18n.availableLocales.map(e => {
      return { value: e, text: this.$i18n.messages[e].lang };
    });
    this.$i18n.locale = localStorage.getItem(this.CURRENT_LANG) || "en";
  },
  computed: {
    system_lang() {
      return require("../../../assets/" + this.$i18n.locale + "_flag.png");
    }
  },
  methods: {
    changeLang(item) {
      this.$i18n.locale = item;
      localStorage.setItem(this.CURRENT_LANG, item);
    },
    getImgUrl(value) {
      return require("../../../assets/" + value + "_flag.png");
    }
  }
};
</script>
<style scoped>
.absolute-lang {
  position: absolute;
  top: 10px !important;
  right: 20px !important;
}
</style>