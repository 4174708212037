/**
 * 
 * Siempre se retorna como error una referencia a la key del lenguaje para que se procese
 * y se muestre el error según el lenguaje seleccionado por el usuario
 * ej: rules.required -> busca en @plugins/locales/en | es para mostrar el mensaje
 */

const rule_required = (v) => !!v || 'rules.required'

// formato de celulares USA
const ruel_cell_phone = value => /^\d{3}-\d{3}-\d{4}$/.test(value) || 'rules.cell_phone'
const ruel_home_phone = value => (/^\d{3}-\d{3}-\d{4}$/.test(value) || value == '' || value == null) || 'rules.cell_phone'

const ruel_email = value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'rules.email'
}

const generateMaxLength_10 = (v) => v.length <= 10 || "rules.max_length_10"
const rule_graduation_year = (v) => /^\d{4}$/.test(v) || 'rules.graduation_year'
const rule_hours = (v) => /^[0-9]*$/.test(v) || 'rules.hours'

// valida los valores para la numeracion del los Attending 1 al 99 y V
// const rule_attending_nro = (v) => /^([1-9]|[1-9][0-9])$/.test(v) || 'rules.attending_nro'





/*************************************************************************************************
 * 
 * Todas las reglas esctiras en estos mixins son con el objetivo de posibilitar la reaccion de los
 * mensajes de error de los <inputs> al cambio de idioma del usuario, por eso para ello se utilizan
 * las propiedades computadas en lugar de las reglas directamente, asi pueden responder al cambio de idioma
 * 
***************************************************************************************************/

/**
 * Validaciones del Login
 */
export const loginMixin = {
    computed: {
        rules_password() {
            return [rule_required];
        },
        rules_user() {
            return [rule_required, ruel_email];
        },
    },
}

/**
 * Validacion para solo required...
 */
export const onlyReqiredMixin = {
    computed: {
        onlyRequired() {
            return [rule_required];
        },
    },
}

/**
 * Validation para Usuarios Basicos
 */
export const userValidations = {
    computed: {
        rules_required() {
            return [rule_required];
        },
        rules_email_required() {
            return [rule_required, ruel_email];
        },
        rules_cell_phone() {
            return [rule_required, ruel_cell_phone];
        },
        rules_home_phone() {
            return [ruel_home_phone];
        },
        qgenda_rules() {
            return [generateMaxLength_10]
        },
        rules_graduation_year() {
            return [rule_required, rule_graduation_year]
        },
        rules_hours() {
            return [rule_hours, rule_required]
        }
    },
}