export default {

    // ------- Login -------
    lang: "English",
    login__forgot_password: {
        forgot: "Forgot your password? ",
        retrieve: "Retrieve your password",
        email: " with your registred e-mail address."
    },
    email: "Email",
    password: "Password",
    password_confirm: "Password Confirm",
    login: "Login",
    reset_pass: "Reset Password",
    reset_pass_confirm: "An email with a reset link has been sent",
    reset_pass_error: "There was a problem, We cannot find an account with that email address. Please contact your administrator.",
    error_login: 'Incorrect email or password.',

    logout: "Logout",
    profile: "Profile",


    // ------------- Validations -----------
    rules: {
        required: "required.",
        email: 'Invalid e-mail.',
        max_length_10: 'ensure this field has no more than 10 characters.',
        cell_phone: 'phone format : ###-###-####',
        graduation_year: 'incorrect format: ####',
        hours: 'this field is numeric'
    },

    // ------------- 404 -----------
    error_404: {
        error: "Error 404",
        sorry: 'Sorry, page not found',
        back: 'back',
        go_home: 'go home'
    },

    // ------------ Forms User -------------
    search: "Search",
    add: "Add",
    name: 'Name',
    first_name: "First Name",
    last_name: "Last Name",
    cell_phone: "Cell Phone",
    home_phone: "Home Phone",
    home_address: "Home Address",
    start_date: "Start Date",
    biography: "Biography",
    admin_privileges: "Admin Privileges",
    is_active: "Active",
    social_media: "Social Media",
    emergency_name: "Emergency Contact, Name",
    emergency_relationship: "Emergency Contact, Relationship",
    emergency_number: "Emergency Contact, Number",

    hours: "Hours",
    level: "Level",
    graduation_year: "Graduation Year",
    school: "School",

    cancel: "Cancel",
    open: "Open",
    close: "Close",
    edit: "Edit",
    delete: "Delete",
    confirm: "Confirm",
    save: "Save",
    send: 'Send',
    find: 'Find',
    clear: 'Clear',
    save_and_other: "SAVE AND ADD OTHER",
    add_social_media: "Add Social Media",
    use_user: "the user is in use, it cannot be deleted",
    max_size_avatar: "avatar must not exceed 1 MB",

    // ------------- MSG ----------------
    save_success: "data saved successfully!",
    delete_success: "data deleted successfully!",
    confirm_delete: "Confirm delete",
    confirm_: "Confirm?",

    delete_confirm_role: "Are you sure you want to delete the role ?",
    delete_confirm_user: "Are you sure you want to delete the User ?",
    delete_confirm_area: "Are you sure you want to delete the Area ?",
    delete_confirm_holiday: "Are you sure you want to delete the holiday ?",
    pass_not_matches: "Passwords don't match",

    repite_no: "Positions is in use",
    invalida_no: "not accepted format position, must be (1 ... 99, N) and not (P1C, P2C, V)",
    not_vac: "not accepted format V or Vac",
    noedit_pxc: "P1C, P2C and Vacation cannot be edited",
    noedit_change: "can't not edit, goto Comprehensive Calendar",
    email_success: "emails sent successfully",

    // -------------------- Daily Work Schedule --------------------

    actions: "actions",
    print: "Print",
    notify: "Notify",
    assignment: "Assignment...",
    location: "location",
    drag_drop: "Drag & Drop is On",
    drag_drop_off: "Drag & Drop is Off",
    autonumber: "Click to reassign numbers",
    today: "Today",

    // area
    manage_area: "Manage Area",
    new_area: "Create Area",
    edit_area: "Edit Area",
    view_area: "View Area",
    close_view_area: "Close view area",
    use_area: "This area is in use by the Daily Work Schedule ",
    description: "Description",
    show: "show",

    // alert
    alert: "Alert",
    new_alert: "New Alert",
    send_email_text: "Send Email Notification for update to Daily Work Schedule",

    // -------------------- Vacations --------------------
    request: 'Request history',
    from: 'From',
    to: 'To',
    meeting_name: 'Meeting Name',
    meeting_location: 'Meeting Location',
    vacation: 'Vacation',
    meeting: 'Meeting',
    type_request: 'Type of Request',
    new_request: 'Submit New Request',
    period_request: 'Period Requested',
    data_submitted: 'Data submitted',
    comments: 'Comments',
    read_more: 'read more',
    staff_type: 'Staff Type',
    select_user: 'Select User',
    aler_msg_dashboard: "A new vacation request has been submitted, go to",
    wrong_request_date: "You can not make a vacation request for this past period",
    search_request: "Search Request History",

    //STATES
    New: 'New',
    Approved: 'Approved',
    Pending: 'Pending',
    Denied: 'Denied',
    Conflict: 'Conflict',

    // -------------------- Comprehensive --------------------
    cancel_change: 'Cancel Change',


}